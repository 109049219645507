import React from 'react'
import { FormControl } from 'components'
import { Input } from 'ui'
import { ArchiveContext } from '../../context'
import { Core } from 'core'

export default function Search({ label }: SearchProps) {
  const { setParams, model } = React.useContext(ArchiveContext)
  const [initialized, setInitialized] = React.useState(false)
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    if (initialized) {
      const timeout = setTimeout(() => onSearch(value), 1000)

      return () => clearTimeout(timeout)
    } else {
      setInitialized(true)
    }
  }, [value])

  function onSearch(value: string) {
    setParams((prev: any) => ({ ...prev, search: { ...prev.search, value } }))
  }

  return (
    <FormControl
      component={Input}
      label={{ text: label || Core[model].labels.search, style: 'bold' }}
      value={value}
      onChange={(value) => setValue(value)}
      style={{ flexGrow: 1 }}
    />
  )
}

type SearchProps = {
  label?: string
}
