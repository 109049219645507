import React from 'react'
import Col from './Col'
import styles from './Table.module.scss'

export default function Header({ children }: HeaderProps) {
  return <tr className={styles.header}>{children}</tr>
}

type HeaderProps = {
  children: React.ReactElement<typeof Col> | React.ReactElement<typeof Col>[]
}
