import React from 'react'
import { Settings } from 'ui'
import { Core } from 'core'
import { useDelete } from 'hooks'
import { ArchiveContext } from '../../context'

export default function Delete({ id, params }: { id: number; params?: any }) {
  const { model } = React.useContext(ArchiveContext)
  const handleDelete = useDelete(model)
  const show = Core[model].capabilities.delete === true

  return (
    <Settings.Option
      show={show}
      style={{ color: 'var(--danger)' }}
      onClick={() => handleDelete(id, params)}
    >
      Удалить
    </Settings.Option>
  )
}
