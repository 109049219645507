import React from 'react'
import { Page, Table } from 'ui'
import { useModalStore } from 'store'
import { Report } from './modules'
import { Core } from 'core'

export default function Settings() {
  const modal = useModalStore()

  const style: React.CSSProperties = {
    color: 'var(--primary)',
    cursor: 'pointer',
    textDecoration: 'underline',
  }

  const capabilities = {
    parks: Core.Park.capabilities.report,
    salary: Core.User.capabilities.salary,
  }

  return (
    <Page>
      <Page.Header title="Отчеты" />
      <Table>
        <Table.Header>
          <Table.Col>Тип отчета</Table.Col>
          <Table.Col> </Table.Col>
        </Table.Header>
        {capabilities.parks && (
          <Table.Row>
            <Table.Col>Отчет по паркам</Table.Col>
            <Table.Col onClick={() => modal.open(<Report type="PARKS" />)} style={style}>
              Составить отчет
            </Table.Col>
          </Table.Row>
        )}
        {capabilities.salary && (
          <Table.Row>
            <Table.Col>Отчет по зарплате</Table.Col>
            <Table.Col onClick={() => modal.open(<Report type="SALARY" />)} style={style}>
              Составить отчет
            </Table.Col>
          </Table.Row>
        )}
      </Table>
    </Page>
  )
}
