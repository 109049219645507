import { TImage } from '../types'

export default async function handleDelete(
  setGallery: (images: TImage[] | ((prev: TImage[]) => TImage[])) => void,
  setValue: (urls: string[] | ((prev: string[]) => string[])) => void,
  i: number
) {
  setGallery((prev) => prev.filter((_, j) => i !== j))
  setValue((prev) => prev.filter((_, j) => i !== j))
}
