import React from 'react'
import styles from './Input.module.scss'
import { InputProps } from './types'
import Inputmask from 'inputmask'

export default function Input({ type = 'text', mask, ...props }: InputProps) {
  const tagName = type === 'textarea' ? 'textarea' : 'input'
  const inputRef = React.useRef<HTMLInputElement>()

  React.useEffect(() => {
    if (mask) {
      const im = new Inputmask(mask)
      im.mask(inputRef.current)
    }
  }, [mask])

  return React.createElement(tagName, {
    type,
    ...props,
    value: props.value
      ? type === 'date'
        ? // @ts-ignore
          props.value.split('T')[0]
        : props.value
      : undefined,
    ref: inputRef,
    className: styles.fluid,
  })
}
