import React from 'react'
import { HandySvg } from 'handy-svg'
import styles from './Gallery.module.scss'
import { handleUpload, handleDelete } from './handlers'
import classNames from 'classnames'
import { FilesApi } from 'core'
import { flattenObject } from 'utils'
import { FileUpload } from 'ui'
import Item from './Item'
import Variants from './Variants'
import { GalleryProps, TImage } from './types'

import iconUpload from 'assets/svg/upload-image.svg'

export default function Gallery({
  className,
  folder,
  error,
  initial,
  name,
  setFormData,
}: GalleryProps) {
  const [opened, setOpened] = React.useState<'VARIANTS' | 'YOUTUBE'>(null)

  const [dragActiveIndex, setDragActiveIndex] = React.useState(null)
  const [dragTargetIndex, setDragTargetIndex] = React.useState(null)

  const [value, setValue] = React.useState<string[]>(initial)
  const [gallery, setGallery] = React.useState<TImage[]>(
    initial.map((path) => ({
      src: path.includes('youtube:') ? path : FilesApi.loadFile(path),
      uploaded: true,
    }))
  )

  React.useEffect(() => {
    setFormData((prev) => flattenObject.set(name, value, prev))
  }, [value])

  async function onUpload(files: File[]) {
    handleUpload(files, setGallery, setValue, folder)
    setOpened(null)
  }

  function onDragEnd(position: 'LEFT' | 'RIGHT') {
    if (dragTargetIndex !== dragActiveIndex) {
      const targetIndex =
        position === 'LEFT' ? dragTargetIndex - 1 : dragTargetIndex

      setGallery((prev) => prev.move(prev, dragActiveIndex, targetIndex))
      setValue((prev) => prev.move(prev, dragActiveIndex, targetIndex))
    }

    setDragActiveIndex(null)
    setDragTargetIndex(null)
  }

  const youtubeButtonRef = React.useRef<HTMLElement>(null)

  function handleToggleVariants(e: React.MouseEvent<HTMLElement>) {
    const target = e.target as HTMLElement
    const variant = target.dataset.target

    if (target.tagName === 'INPUT' || variant === 'photo') {
      return null
    }

    e.preventDefault()

    if (youtubeButtonRef.current && youtubeButtonRef.current.contains(target)) {
      return setOpened(null)
    }

    if (variant === 'youtube' || target.closest('[data-target="youtube"]')) {
      return setOpened('YOUTUBE')
    }

    setOpened('VARIANTS')
  }

  function handleAddYoutube(src: string) {
    setGallery((prev) => [...prev, { src, uploaded: true }])
    setValue((prev) => [...prev, src])
  }

  return (
    <div className={classNames(styles.gallery, className)}>
      <div className={styles.row}>
        <FileUpload
          multiple={true}
          onChange={onUpload}
          className={classNames(styles.drop, opened && styles.drop_opened)}
          onClick={handleToggleVariants}
          dragAndDrop={true}
        >
          {opened && (
            <Variants
              variant={opened}
              youtubeButtonRef={youtubeButtonRef}
              onAdd={(src) => handleAddYoutube(src)}
            />
          )}
          <HandySvg src={iconUpload} />
          <div className={styles.title}>Нажмите или перетащите файлы</div>
          <div className={styles.subtitle}>Доступны PNG, JPG и YouTube</div>
        </FileUpload>
        {gallery.map(({ src, uploaded }, i) => (
          <Item
            key={src}
            src={src}
            uploaded={uploaded}
            onDragEnd={(position) => onDragEnd(position)}
            onDragStart={() => setDragActiveIndex(i)}
            onDragEnter={() => setDragTargetIndex(i)}
            onDelete={() => handleDelete(setGallery, setValue, i)}
          />
        ))}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}
