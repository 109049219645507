import React from 'react'
import styles from './Page.module.scss'
import classNames from 'classnames'
import Header from './Header'

const Page = React.forwardRef(function Page(
  { className, children, style }: PageProps,
  ref: React.RefObject<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={classNames(styles.container, className)}
      style={style}
    >
      {children}
    </div>
  )
})

const Compounded = Page as CompoundedType
Compounded.Header = Header

export default Compounded

export interface PageProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type CompoundedType = typeof Page & {
  Header: typeof Header
}
