import React, { useEffect, useRef } from 'react'
import styles from './Table.module.scss'
import Col from './Col'
import Header from './Header'
import Row from './Row'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar, FreeMode } from 'swiper'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'

export default function Table({
  children,
  refreshKey,
}: TableProps & { refreshKey?: string }) {
  const $swiper = useRef(null)

  useEffect(() => {
    $swiper.current.update()
  }, [refreshKey, $swiper])

  return (
    <Swiper
      onInit={(swiper) => ($swiper.current = swiper)}
      slidesPerView="auto"
      modules={[Scrollbar, FreeMode]}
      scrollbar={{ draggable: true }}
      className={styles.container}
      // mousewheel
      grabCursor
      freeMode
    >
      <SwiperSlide style={{ width: 'initial', minWidth: '100%' }}>
        <table style={{ width: '100%' }}>
          <tbody>{children}</tbody>
        </table>
      </SwiperSlide>
    </Swiper>
  )
}

Table.Col = Col
Table.Header = Header
Table.Row = Row

type TableProps = {
  children: any[]
}
