import React from 'react'
import { HandySvg } from 'handy-svg'
import { Droplist } from 'ui'
import { handleChange } from './handlers'
import styles from './Select.module.scss'
import { SelectProps } from './types'

import iconChevron from 'assets/svg/chevron.svg'
import { useChange, useLazy } from './hooks'

export default function Select({
  lazy,
  multiple = false,
  onChange,
  value,
  ...props
}: SelectProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [opened, setOpened] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState(
    props.options ? props.options : []
  )

  let selected = multiple
    ? options.filter(({ selected }) => selected).map(({ value }) => value)
    : options.find(({ selected }) => selected)

  if (!multiple) {
    // @ts-ignore
    selected = selected ? selected.value : null
  }

  const allSelected = React.useMemo(
    () =>
      options.length > 0 &&
      Array.isArray(selected) &&
      selected.length === options.length,
    [options]
  )
  const lazyData = useLazy({ lazy, opened, setOptions })

  const event: any = {
    options,
    setOptions,
    selected,
    lazyData,
  }

  useChange({ onChange, options, event })

  function handleToggle() {
    setOpened(!opened)
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.fluid} onClick={handleToggle}>
        <span>
          {allSelected && props.all
            ? props.all
            : allSelected
            ? 'Все'
            : options
                .filter(({ selected }) => selected)
                .map(({ text }) => text)
                .join(', ')}
        </span>
        <HandySvg src={iconChevron} />
      </div>
      {opened && (
        <Droplist
          opened={opened}
          setOpened={setOpened}
          containerRef={containerRef}
          className={styles.droplist}
        >
          {multiple && (
            <Droplist.Option
              onClick={() =>
                handleChange({ i: null, multiple, setOptions, setOpened })
              }
              highlighted={allSelected}
            >
              {props.all ?? 'Выбрать все'}
            </Droplist.Option>
          )}
          {options.map(({ text, selected }, i) => (
            <Droplist.Option
              key={i}
              onClick={() =>
                handleChange({ i, multiple, setOptions, setOpened })
              }
              highlighted={selected}
            >
              {text}
            </Droplist.Option>
          ))}
        </Droplist>
      )}
    </div>
  )
}
