import React from 'react'
import { Select } from 'ui'
import Control from './Control'
import { ControlProps } from './types'

export default function SelectGender<C extends typeof Select>({
  multiple = false,
  name,
  ...props
}: Omit<ControlProps<C>, 'component' | 'options'>) {
  return (
    <Control
      component={Select}
      multiple={multiple}
      name={name}
      options={[
        { text: 'Мужской', value: 'MALE', selected: false },
        { text: 'Женский', value: 'FEMALE', selected: false },
      ]}
      {...props}
    />
  )
}
