import React from 'react'
import classNames from 'classnames'
import styles from './Card.module.scss'
import Buttons from './Buttons'
import ButtonEdit from './ButtonEdit'
import ButtonView from './ButtonView'
import CardButton from './Button'
import {
  Comment,
  Equipment,
  Gallery,
  Header,
  Price,
  Separator,
  Status,
  GalleryProps,
} from './components'

export default function Card({
  children,
  className,
  gallery,
  style,
  variant = 'CATALOG',
}: CardProps) {
  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.container,
          className,
          `--${variant.toLowerCase()}-card`
        )}
        style={style}
      >
        <Gallery gallery={gallery} />
        <div className={styles.content}>{children}</div>
      </div>
    </React.Fragment>
  )
}

Card.Buttons = Buttons
Card.ButtonEdit = ButtonEdit
Card.ButtonView = ButtonView
Card.Button = CardButton
Card.Comment = Comment
Card.Equipment = Equipment
Card.Header = Header
Card.Price = Price
Card.Separator = Separator
Card.Status = Status

type CardProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  variant?: 'CATALOG' | 'MODAL' | 'SMALL'
} & GalleryProps
