import React from 'react'
import { Button, Input } from 'ui'
import { FormControl } from 'components'
import styles from './Gallery.module.scss'

export default function Variants({
  variant,
  youtubeButtonRef,
  onAdd,
}: VariantsProps) {
  const [youtubeId, setYoutubeId] = React.useState('')

  function handleAdd() {
    onAdd('youtube:' + youtubeId)
    setYoutubeId('')
  }

  return (
    <div className={styles.variants}>
      {variant === 'VARIANTS' && (
        <React.Fragment>
          <span data-target="photo" className={styles.variant}>
            Фото
          </span>
          <span data-target="youtube" className={styles.variant}>
            Youtube-видео
          </span>
        </React.Fragment>
      )}
      {variant === 'YOUTUBE' && (
        <div className={styles.youtube} data-target="youtube">
          <FormControl
            component={Input}
            label={{ text: 'ID видео', style: 'bold' }}
            value={youtubeId}
            onChange={setYoutubeId}
            className={styles.youtubeId}
            style={{ flexGrow: 1 }}
          />
          <Button
            ref={youtubeButtonRef}
            component="div"
            text="Добавить"
            onClick={handleAdd}
          />
        </div>
      )}
    </div>
  )
}

type VariantsProps = {
  variant: 'VARIANTS' | 'YOUTUBE'
  youtubeButtonRef: React.RefObject<HTMLElement>
  onAdd: (src: string) => void
}
