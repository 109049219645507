import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import Router from 'Router'

export default function App() {
  return (
    <CookiesProvider>
      <RouterProvider router={Router} />
    </CookiesProvider>
  )
}
