import Model from 'core/Model'

const inventoryTypeSchema = {
  id: NaN,
  key: '',
  title: '',
}

export const InventoryType = new Model<TInventoryType>({
  url: '/inventory-types',
  schema: inventoryTypeSchema,
  labels: { archive: '', create: '', creating: '', updating: '' },
})

export type TInventoryType = typeof inventoryTypeSchema
