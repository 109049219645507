import Model from 'core/Model'
import Api from 'core/Api'
import { TInventory } from '../Inventory/Inventory'
// import { TProgramCategory } from '../ProgramCategory/ProgramCategory'
import { TProgramRate } from '../ProgramRate/ProgramRate'
import { TProgramStatus } from '../ProgramStatus/ProgramStatus'
import { TProgramType } from '../ProgramType/ProgramType'
import { TUser } from '../User/User'
import { TUserRole } from '../UserRole/UserRole'
import { TParty } from '../Party/Party'
import { TPartyProgram } from '../PartyProgram/PartyProgram'

const programSchema = {
  id: NaN,
  title: '',
  duration: NaN,
  description: '',
  rates: [],
  gallery: [],
  statusKey: '',
  typeKey: '',
  // categories: [],
  executors: [],
  inventoryGroups: [],
}

class ProgramApi<TProgram> extends Api<TProgram> {
  async checkAvailable(id: number, data: CheckAvailableParams) {
    const url = [this.url, id, 'available'].join('/')
    return await this.instance.get(url, {
      params: { ...data, format: 'string' },
    })
  }

  async getExecutors(id: number, params: GetExecutorsParams) {
    const url = [this.url, id, 'actors'].join('/')
    return await this.instance
      .get<({ available: boolean } & TUser)[]>(url, {
        params,
      })
      .then(({ data }) => data)
  }

  async getInventories(id: number, data: GetInventoriesParams) {
    const url = [this.url, id, 'inventories'].join('/')
    return await this.instance
      .get<GetInventoriesResponse>(url, {
        params: data,
      })
      .then(({ data }) => data)
  }

  async calculateCost(id: number, params: CalculateCostData) {
    const url = [this.url, id, 'calculate-cost'].join('/')

    return await this.instance.get<number>(url, { params })
  }
}

export const Program = new Model<TProgram, ProgramApi<TProgram>>({
  Api: ProgramApi,
  associations: [{ model: programSchema, as: 'status' }],
  url: '/programs',
  schema: programSchema,
  labels: {
    archive: 'Программы',
    create: 'Добавить программу',
    search: 'Поиск по программам',
    creating: 'Добавление программы',
    updating: 'Редактирование программы',
    menuText: 'Программы',
  },
  capabilities: {
    calculateCost: 'get.programs/:id/calculate-cost',
  },
})

type PartiesPrograms = {
  id: number
  cost: number
  from: string
  to: string
  rateId: number
  success: boolean
  partyId: number
  programId: number
}

type TProgramInventoryGroup = {
  id: number
  title: string
  inventories: TInventory[]
}

type TProgramExecuror = {
  id: number
  programId: number
  type: 'USERS' | 'ROLES'
  users?: TUser[]
  roles?: TUserRole[]
}

export type TProgram = Omit<
  typeof programSchema,
  'executors' | 'inventoryGroups' | 'categories' | 'rates'
> & {
  // categories?: TProgramCategory[]
  executors: TProgramExecuror[]
  inventoryGroups: TProgramInventoryGroup[]
  rates?: TProgramRate[]
  status?: TProgramStatus
  type?: TProgramType
  PartiesPrograms: PartiesPrograms
}

type CheckAvailableParams = Pick<TParty, 'date' | 'parkId'>

type GetExecutorsParams = Pick<TParty, 'date' | 'parkId'> &
  Pick<TPartyProgram, 'from' | 'to'> & {
    search?: string
  }

type GetInventoriesParams = Pick<TParty, 'date' | 'parkId'> &
  Pick<TPartyProgram, 'from' | 'to'>

export type GetInventoriesResponse = (Omit<
  TProgramInventoryGroup,
  'inventories'
> & {
  inventories: (TInventory & { available: boolean })[]
})[]

interface CalculateCostData {
  duration: number
  parkId: number
  rateId: number
  persons: number
}
