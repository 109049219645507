import React from 'react'
import { FormControl, FormControlRoots, FormControlProps } from 'components'
import { flattenObject } from 'utils'
import { ArchiveContext } from '../../context'

export default function Control<C extends FormControlRoots>({
  initial = '',
  name,
  ...props
}: ControlProps<C>) {
  const { setParams } = React.useContext(ArchiveContext)
  const [value, setValue] = React.useState(initial)

  function handleChange(value: any) {
    setValue(value)

    setParams((prev) => flattenObject.set(name, value, prev))
  }

  // @ts-ignore
  return <FormControl value={value} onChange={handleChange} {...props} />
}

type GetPropsWithOverride<Props, C extends FormControlRoots> = Props &
  Omit<React.ComponentProps<C>, keyof Props>

type ControlProps<C extends FormControlRoots> = GetPropsWithOverride<
  Omit<FormControlProps<C>, 'onChange'> & {
    initial?: string
    name: string
  },
  C
>
