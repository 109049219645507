import React from 'react'
import { Core } from 'core'
import { useAlertStore } from 'store'

export default function useDelete(modelName: keyof typeof Core) {
  const alert = useAlertStore()
  const model = Core[modelName]

  function handleDelete(id: number, params: any) {
    alert.confirm({
      title: 'Подтвердите удаление',
      buttons: [
        { text: 'Отмена', onClick: alert.clear, theme: 'LIGHT' },
        {
          text: 'Удалить',
          onClick: () => onConfirm(id, params),
          theme: 'DANGER',
        },
      ],
    })
  }

  async function onConfirm(id: number, params: any) {
    await model.api.delete(id, params).then(onSuccess)
  }

  function onSuccess() {
    alert.success({
      title: model.labels.onDelete,
      buttons: [{ text: 'Ок', onClick: alert.clear }],
    })
  }

  return (id: number, params: any = {}) => handleDelete(id, params)
}
