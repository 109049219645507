import React from 'react'
import FsLightbox from 'fslightbox-react'
import { Alert } from 'ui'
import styles from './styles/Overlay.module.scss'
import { useAlertStore, useModalStore } from 'store'

function Modal({ children }) {
  const [toggler, setToggler] = React.useState(false)
  React.useEffect(() => setToggler(!toggler), [])
  const modal = useModalStore()

  return (
    <FsLightbox
      disableBackgroundClose={true}
      exitFullscreenOnClose={true}
      toggler={toggler}
      onClose={modal.close}
      sources={
        Array.isArray(children)
          ? children.map((child, i) => (
              <React.Fragment key={i}>{child}</React.Fragment>
            ))
          : [<React.Fragment>{children}</React.Fragment>]
      }
    />
  )
}

export default function Overlay() {
  const [alertToggler, setAlertToggler] = React.useState(false)

  const alert = useAlertStore()
  const modal = useModalStore()

  React.useEffect(() => {
    if (alert.state) {
      setAlertToggler(true)
    } else {
      setAlertToggler(false)
    }
  }, [alert.state])

  return (
    <React.Fragment>
      <div className={styles.overlay}>
        {modal.state.map((modal, i: number) => (
          <Modal key={i}>{modal}</Modal>
        ))}
        <FsLightbox
          onClose={() => {
            alert.clear()
            setAlertToggler(false)
          }}
          loadOnlyCurrentSource={true}
          exitFullscreenOnClose={true}
          disableBackgroundClose={true}
          toggler={alertToggler}
          sources={[<Alert />]}
        />
      </div>
    </React.Fragment>
  )
}
