import Api from './Api'

interface ILabels {
  archive: string
  create: string
  delete?: string
  onDelete?: string
  search?: string
  creating: string
  updating: string
  menuText?: string
}

export default class Model<
  T,
  TApi extends Api<T> = Api<T>,
  Cap extends string[] = []
> {
  url: string
  schema: any
  api: TApi
  labels?: ILabels
  associations?: {
    model: Model<any> | any
    as: string
  }[]
  capabilities?: {
    create?: boolean | string
    findAll?: boolean | string
    findOne?: boolean | string
    update?: boolean | string
    delete?: boolean | string
  } & {
    [K in Cap as string]?: boolean | string
  }

  constructor(options: Omit<Model<TApi>, 'api'> & { Api?: typeof Api }) {
    // @ts-ignore
    this.api = options.Api
      ? new options.Api<T>(options.url)
      : new Api<T>(options.url)
    this.associations = options.associations
    this.labels = {
      delete: 'Подтвердите удаление',
      onDelete: 'Успешно',
      ...options.labels,
    }
    this.schema = options.schema
    this.url = options.url
    this.capabilities = {
      create: 'post' + this.url.replace('/', '.'),
      findAll: 'get' + this.url.replace('/', '.'),
      findOne: 'get' + this.url.replace('/', '.') + '/:id',
      update: 'put' + this.url.replace('/', '.') + '/:id',
      delete: 'delete' + this.url.replace('/', '.') + '/:id',
      ...options.capabilities,
    }
  }
}
