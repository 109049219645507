import React from 'react'

export default function useDocumentClick({
  opened,
  setOpened,
  containerRef,
}: UseDocumentClickOptions) {
  React.useEffect(() => {
    if (opened) {
      document.addEventListener('click', handleClose)
    } else {
      document.removeEventListener('click', handleClose)
    }

    function handleClose(e: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as any)
      ) {
        setOpened(false)
      }
    }
  }, [opened])
}

export type UseDocumentClickOptions = {
  opened: boolean
  setOpened: (opened: boolean | ((prev: boolean) => boolean)) => void
  containerRef: React.RefObject<HTMLElement>
}
