import React from 'react'
import Col from './Col'
import styles from './Table.module.scss'

export default function Row({ children }) {
  return <tr className={styles.row}>{children}</tr>
}

type HeaderProps = {
  children: React.ReactElement<typeof Col> | React.ReactElement<typeof Col>[]
}
