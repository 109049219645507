import React from 'react'
import Button from './Button'
import iconEye from 'assets/svg/eye.svg'

export default function ButtonView({ onClick }) {
  return (
    <Button
      component="button"
      theme="LIGHT"
      circle={true}
      icon={iconEye}
      onClick={onClick}
    />
  )
}
