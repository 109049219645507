import { create } from 'zustand'

export const useModalStore = create<ModalStore>((set, store) => ({
  state: [],
  open: (target) => {
    const state = [...store().state, target]
    set((store) => ({ ...store, state }))
  },
  close: () => {
    const state = store().state.slice(0, -1)
    set((store) => ({ ...store, state }))
  },
}))

type ModalStore = {
  state: JSX.Element[]
  open: (target: JSX.Element) => void
  close: () => void
}
