import React from 'react'
import styles from './Card.module.scss'
import { Button, Icon } from 'ui'
import { useWindowDimensions } from 'hooks'
import { ButtonRoots, ButtonProps } from 'ui/Button/types'
import { modifiedClassName } from 'utils'

function CardButton<C extends ButtonRoots = 'button'>({
  circle,
  ...props
}: CardButtonProps<C>) {
  const variant = 'SMALL'

  const { width } = useWindowDimensions()
  const [style, setStyle] = React.useState(props.style)
  const buttonRef = React.useRef<HTMLElement>()

  React.useEffect(() => {
    if (circle) {
      setStyle((prev) => ({
        ...prev,
        padding: 0,
        width: buttonRef.current.offsetHeight,
        height: buttonRef.current.offsetHeight,
      }))
    }
  }, [width])

  return (
    <Button
      {...props}
      ref={buttonRef}
      style={style}
      className={modifiedClassName(styles, 'button', variant)}
    />
  )
}

type CompoundedType = typeof CardButton & {
  Circle: typeof Circle
}

const Compounded = CardButton as CompoundedType
Compounded.Circle = Circle
export default Compounded

function Circle<C extends ButtonRoots = 'button'>({
  icon,
  ...props
}: CircleButtonProps<C>) {
  const variant = 'SMALL'

  const { width } = useWindowDimensions()
  const [style, setStyle] = React.useState({ ...props.style, padding: 0 })
  const buttonRef = React.useRef<HTMLElement>()

  React.useEffect(() => {
    setStyle((prev) => ({
      ...prev,
      width: buttonRef.current.offsetHeight,
      height: buttonRef.current.offsetHeight,
    }))
  }, [width])

  return (
    <Button
      {...props}
      ref={buttonRef}
      style={style}
      className={modifiedClassName(styles, 'button', variant)}
      size={variant}
    >
      <Icon name={icon} />
    </Button>
  )
}

type CircleButtonProps<C extends ButtonRoots = 'button'> = ButtonProps<C> & {
  icon: string
}

type CardButtonProps<C extends ButtonRoots = 'button'> = ButtonProps<C> & {
  circle?: true
}
