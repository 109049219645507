import React from 'react'
import classNames from 'classnames'
import styles from './Equipment.module.scss'

export default function Equipment({
  activeImage,
  equipment,
  onItemClick,
}: EquipmentProps) {
  return (
    <div>
      {equipment.map(({ count, image, title }, i) => (
        <div
          key={i}
          className={classNames(
            styles.item,
            activeImage === image && styles.item_active,
            equipment.find(({ image }) => image) && styles.item_hover
          )}
          onClick={onItemClick ? () => onItemClick(i) : undefined}
        >
          <span>{title}</span>
          <span></span>
          <span>x{count}</span>
        </div>
      ))}
    </div>
  )
}

function EquipmentItem() {}

type EquipmentProps = {
  // equipment: Core.TInventoryEquipment[]
  equipment: any[]
  activeImage?: string
  onItemClick?: (i: number) => void
}
