import React from 'react'
import styles from './styles/Sidebar.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { Core } from 'core'
import classNames from 'classnames'
import { useWindowDimensions } from 'hooks'
import { useAuthStore } from 'store'

export default function Sidebar({ opened }: SidebarProps) {
  const { user } = useAuthStore()
  const { width } = useWindowDimensions()
  const [menu, setMenu] = React.useState<any[]>([])
  const { pathname } = useLocation()

  const showInventoryShedule = ['DRESSER', 'SUDO', 'DEVELOPER'].includes(user.role)

  React.useEffect(() => {
    async function checkPermissions() {
      for (let model of Object.keys(Core)) {
        if (model === 'InventoryCategory') {
          continue
        }

        const allowed = Core[model].capabilities.findAll === true

        if (allowed) {
          setMenu((prev) => (prev.indexOf(model) === -1 ? [...prev, model] : prev))
        }
      }
    }

    checkPermissions()
  }, [])

  return (
    <div className={classNames(styles.container, opened && styles.opened)}>
      {menu
        .filter((name) => Core[name].labels && Core[name].labels.menuText)
        .map((model) => (
          <Link
            key={model}
            to={Core[model].url}
            className={pathname.includes(Core[model].url) ? styles.active : undefined}
          >
            {Core[model].labels.menuText}
          </Link>
        ))}
      {showInventoryShedule && (
        <Link to="/inventory-parks" className={styles.settings}>
          Таблица инвентаря
        </Link>
      )}
      {width <= 600 && (
        <Link to="/settings" className={styles.settings}>
          Настройки
        </Link>
      )}
    </div>
  )
}

type SidebarProps = {
  opened: boolean
}
