import React from 'react'
import { ModalProps } from './types'

export default function Modal({ archive, initial, onChange }: ModalProps) {
  const [state, setState] = React.useState(initial)
  React.useEffect(() => onChange(state), [state])

  function handleToggle(data: any) {
    if (state.find(({ id }) => id === data.id)) {
      setState((prev) => prev.filter(({ id }) => data.id !== id))
    } else {
      setState((prev) => [...prev, data])
    }
  }

  return React.createElement(archive, {
    ...archive.prototype,
    onChange: handleToggle,
    state,
  })
}
