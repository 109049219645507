import React from 'react'
import { Spinner } from 'ui/Spinner'
import styles from './Price.module.scss'

export default function Value({ calculating, value }: ValueProps) {
  return (
    <div className={styles.value}>
      {calculating ? <Spinner show={true} /> : value}
    </div>
  )
}

type ValueProps = {
  calculating?: boolean
  value: string
}
