import { FilesApi } from 'core'
import { TImage } from '../types'

export default async function handleUpload(
  files: File[],
  setGallery: (images: TImage[] | ((prev: TImage[]) => TImage[])) => void,
  setValue: (urls: string[] | ((prev: string[]) => string[])) => void,
  folder: string
) {
  let uploaded = 0

  files.forEach(async (file: File) => {
    const src = URL.createObjectURL(file)

    setGallery((prev) => [...prev, { src, uploaded: false }])

    const formData = new FormData()
    formData.append('file', file)

    await FilesApi.uploadFiles(folder, formData)
      .then(({ data }) => setValue((prev) => [...prev, data.path]))
      .then(() =>
        setGallery((prev) =>
          prev.map((image, i) => {
            if (i === uploaded) {
              image.uploaded = true
            }

            uploaded++
            return image
          })
        )
      )
  })
}
