import React from 'react'
import Button from './Button'
import { FlattenObjectKeys } from 'types'
import { Core } from 'core'
import { Link } from 'react-router-dom'
import iconPen from 'assets/svg/pen.svg'

type ButtonEditProps = {
  id: number
  model: FlattenObjectKeys<typeof Core>
}

export default function ButtonEdit({ id, model: modelName }: ButtonEditProps) {
  return (
    <Button
      component={Link}
      theme="LIGHT"
      circle={true}
      icon={iconPen}
      to={[Core[modelName].url, 'update', id.toString()].join('/')}
    />
  )
}
