import React from 'react'
import { HandySvg } from 'handy-svg'
import classNames from 'classnames'
import styles from './Button.module.scss'
import { ButtonProps, ButtonRoots } from './types'
import { Spinner } from 'ui'

export default React.forwardRef(function Button<
  C extends ButtonRoots = 'button'
>(
  {
    component = 'button',
    children,
    className,
    fetching,
    icon,
    size,
    text,
    theme = 'PRIMARY',
    ...props
  }: ButtonProps<C>,
  ref?: React.RefObject<HTMLElement>
) {
  const cn = classNames(
    styles.button,
    styles[`button_${theme.toLowerCase()}`],
    size && styles[`button_${size.toLowerCase()}`],
    fetching && styles[`button_fetching`],
    className
  )

  const Component = component

  return (
    <Component className={cn} ref={ref} {...props}>
      <div className={styles.inner}>
        {fetching && <Spinner show={true} />}
        {icon && <HandySvg src={icon} />}
        {text}
        {children}
      </div>
    </Component>
  )
})
