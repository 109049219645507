import React from 'react'
import styles from './Tools.module.scss'
import Control from './Control'
import Row from './Row'
import Search from './Search'
import Select from './Select'
import Tabs from './Tabs'

export default function Tools({ children }: ToolsProps) {
  return <div className={styles.container}>{children}</div>
}

Tools.Control = Control
Tools.Row = Row
Tools.Search = Search
Tools.Select = Select
Tools.Tabs = Tabs

type ToolsProps = { children: React.ReactNode }
