import React from 'react'
import styles from './Table.module.scss'

export default function Col({
  children,
  fallback = '—',
  ellipsis,
  width,
  style,
  ...props
}: ColProps) {
  const ellipsisStyle: React.CSSProperties = ellipsis
    ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
    : {}

  return (
    <td
      {...props}
      className={styles.col}
      style={{ ...style, ...ellipsisStyle }}
      title={ellipsis ? String(children) : undefined}
    >
      {children || fallback}
    </td>
  )
}

type ColProps = {
  children?: React.ReactNode
  fallback?: React.ReactNode
  width?: number
  ellipsis?: boolean
} & React.ComponentPropsWithoutRef<'td'>
