import React from 'react'
import { FormContext } from './Form'
import { LoopProps } from './types'
import { flattenObject } from 'utils'
import { Loop } from './components'
import classNames from 'classnames'

export default function ContainerLoop<Props>({
  className,
  gap,
  name,
  reference,
  ...props
}: LoopProps<Props>) {
  const context = React.useContext(FormContext)
  const { formData, setFormData, errors } = context

  const error = errors && errors.find(({ param }) => param === name)
  const items = flattenObject.get(name, formData)

  function handleAdd() {
    setFormData((prev) =>
      flattenObject.set(
        name,
        [...flattenObject.get(name, prev), reference],
        prev
      )
    )
  }

  function handleDelete(i: number) {
    setFormData((prev) => {
      return flattenObject.set(
        name,
        flattenObject.get(name, prev).filter((_, j: number) => j !== i),
        prev
      )
    })
  }

  return (
    <div className={classNames(className)}>
      <Loop
        error={error && error.msg}
        gap={gap ? gap : { x: 'BIG', y: 'BIG' }}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        items={items}
        name={name}
        {...props}
      />
    </div>
  )
}
