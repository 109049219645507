import { InventoryStatusType } from 'api/endpoints'

type StatusesListItemType = {
  title: string
  nextStatus: InventoryStatusType
}

export const STATUSES_LIST: {
  [key: string]: StatusesListItemType
} = {
  PACKED: { title: 'Доставить', nextStatus: 'DELIVERED' },
  DELIVERED: { title: 'Вернуть', nextStatus: 'RETURNED' },
  RETURNED: { title: 'Собрать', nextStatus: 'PACKED' },
}

export const WITHOUT_STATUS: StatusesListItemType = {
  title: 'Собрать',
  nextStatus: 'PACKED',
}
