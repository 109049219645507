import Model from 'core/Model'

const parkCategorySchema = {
  id: NaN,
  key: '',
  title: '',
}

export const ParkCategory = new Model<TParkCategory>({
  url: '/park-categories',
  schema: parkCategorySchema,
  labels: {
    archive: 'Категории парков',
    create: 'Добавить категорию',
    search: 'Поиск по категориям',
    creating: 'Добавление категории парков',
    updating: 'Редактирование категории парков',
    menuText: 'Категории парков',
  },
})

export type TParkCategory = typeof parkCategorySchema
