import React from 'react'
import styles from './Declensions.module.scss'
import Control from '../../Control'
import { Input } from 'ui'
import classNames from 'classnames'
import { DeclensionsProps } from './types'

const questions = [
  'Кто? Что?',
  'Кого? Чего?',
  'Кому? Чему?',
  'Кого? Что?',
  'Кем? Чем?',
  'О ком? О чем?',
]

const titles = {
  single: 'Единственное число',
  many: 'Множественное число',
}

export default function Declensions({ className, name }: DeclensionsProps) {
  const keys = Object.keys(name)

  return (
    <div className={classNames(className)}>
      <div className="row">
        {keys.map((type) => (
          <div
            key={type}
            className={`col col-${keys.length === 2 ? '6' : '12'}`}
          >
            <div className={styles.title}>{titles[type]}</div>
            <div className="row">
              {questions.map((question, i) => (
                <Control
                  key={i}
                  component={Input}
                  label={{ text: question, style: 'normal' }}
                  name={`${name[type]}[${i}]`}
                  className={classNames(
                    styles.item,
                    `col col-${keys.length === 2 ? '12' : '6'}`
                  )}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
