import React from 'react'
import styles from './Status.module.scss'

export default function Status({ color, text }: StatusProps) {
  return (
    <div className={styles.status} style={{ backgroundColor: color }}>
      {text}
    </div>
  )
}

type StatusProps = { color: string; text: string }
