import axios from './axios'

class FilesApi {
  static async uploadFiles(folder: string, formData: any) {
    return await axios.post('/upload?folder=' + folder, formData)
  }

  static loadFile(path: string) {
    return 'https://crm.show-maximum.ru' + path
    // return process.env.REACT_APP_SERVER_URL + path
  }
}

export default FilesApi
