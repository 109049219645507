import React from 'react'
import styles from './Group.module.scss'
import { GroupProps } from './types'
import { Button } from 'ui'

export default function Group({ button, children, title }: GroupProps) {
  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      {children && <div className="row">{children}</div>}
      {button && (
        <Button component="div" className={styles.button} {...button} />
      )}
    </div>
  )
}
