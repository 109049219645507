const flattenObject = {
  get(name: string, object: any) {
    let value: any

    const keys = name.split('.')

    let key = keys[0]
    const inArray = key.match(/\[(.*)\]/)

    inArray && (key = key.replace(inArray[0], ''))

    value = inArray
      ? object[key]
        ? object[key][inArray[1]]
        : undefined
      : object
      ? object[key]
      : undefined

    return keys.length === 1 ? value : this.get(keys.slice(1).join('.'), value)
  },

  set(name: string, value: any, object = {}): any {
    const keys = name.split('.')
    const isArray = name.match(/\[(.*)\]/)
    const key = isArray ? keys[0].split('[').shift() : keys[0]

    if (keys.length === 1) {
      return {
        ...object,
        [key]: isArray
          ? object[key].map((item: any, i: number) =>
              i === +isArray[1] ? value : item
            )
          : value,
      }
    }

    const nextName = keys.filter((_, i) => i > 0).join('.')

    return {
      ...object,
      [key]: isArray
        ? object[key].map((item: any, i: number) => {
            return i !== +isArray[1].split(']')[0]
              ? item
              : this.set(nextName, value, item)
          })
        : this.set(nextName, value, object[key]),
    }
  },
}

export default flattenObject
