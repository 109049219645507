import React from 'react'
import { useAuthStore } from 'store'
import { Form } from 'components'
import { Input, Button, Select } from 'ui'
import { Core } from 'core'

export default function ParksReport() {
  const { user } = useAuthStore()
  const isActor = user.role === 'ANIMATOR' || user.role === 'SHOWMAN'
  const [errors, setErrors] = React.useState(null)
  const [formData, setFormData] = React.useState({
    from: null,
    to: null,
    id: [],
    roleId: null,
  })

  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      await Core.User.api.salaryActors().then(setData)
    }

    fetchData()
  }, [])

  const [fetching, setFetching] = React.useState(false)
  async function handleSubmit() {
    setFetching(true)
    await Core.User.api
      .salary(formData)
      .then(({ file }) => {
        window.open(process.env.REACT_APP_SERVER_URL + file, '_blank')
        setErrors(null)
      })
      .catch(({ response }) => setErrors(response.data.errors))
      .finally(() => setFetching(false))
  }

  const ActorsSelect = React.useMemo(
    () => () =>
      formData.roleId && (
        <Form.Control
          label={{ style: 'bold', text: 'Актеры' }}
          component={Select}
          name="id"
          multiple={true}
          all={`Все ${data.roles
            .find(({ id }) => id === formData.roleId)
            .title.toLowerCase()}`}
          options={data.actors
            .filter(({ roleId }) => roleId === formData.roleId)
            .map(({ fullName, id }) => ({ text: fullName, value: id }))}
          className="col col-12"
        />
      ),
    [formData.roleId]
  )

  return (
    <Form
      formData={formData}
      setFormData={setFormData}
      errors={errors}
      init={false}
    >
      <Form.Group title="Отчет по зарплате">
        {!data && 'Загрузка...'}
        {data && !isActor && (
          <React.Fragment>
            <Form.Control
              label={{ style: 'bold', text: 'Роль' }}
              component={Select}
              name="roleId"
              options={data.roles.map(({ id, title }) => ({
                text: title,
                value: id,
              }))}
              className="col col-12"
            />
            <ActorsSelect />
          </React.Fragment>
        )}
        <Form.Control
          component={Input}
          name="from"
          type="date"
          label={{ style: 'bold', text: 'От' }}
          className="col col-12"
        />
        <Form.Control
          component={Input}
          name="to"
          type="date"
          label={{ style: 'bold', text: 'До' }}
          className="col col-12"
        />
        <Button
          fetching={fetching}
          component="button"
          text="Составить отчет"
          onClick={handleSubmit}
          className="col col-12"
        />
      </Form.Group>
    </Form>
  )
}
