import Model from 'core/Model'

const inventoryCategorySchema = {
  id: NaN,
  title: '',
}

export const InventoryCategory = new Model<TInventoryCategory>({
  url: '/inventory-categories',
  schema: inventoryCategorySchema,
  labels: {
    archive: 'Категории инвентаря',
    create: 'Добавить категорию',
    search: 'Поиск по категориям',
    creating: 'Добавление категории',
    updating: 'Редактирование категории',
    menuText: 'Категории инвентаря',
  },
})

export type TInventoryCategory = typeof inventoryCategorySchema
