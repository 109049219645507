import { Core } from 'core'
import React from 'react'

export const ArchiveContext = React.createContext<TArchiveContext>(null)

type TArchiveContext = {
  $content: React.RefObject<HTMLDivElement>
  count: number
  model: keyof typeof Core
  params: any
  setParams: React.Dispatch<any>
}
