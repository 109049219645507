import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Layout } from 'layout'
import { Core } from 'core'
import {
  ErrorPage,
  ReportsPage,
  SettingsPage,
  InventoryParkPage,
  InventoryHistoryPage,
} from 'pages'

const routes = Object.keys(Core).map((name: string) => {
  const Archive = React.lazy(async () => {
    return await import(`pages/${name}/Archive/Archive`).catch(() => ({
      default: () => <ErrorPage title="Страница не найдена" />,
    }))
  })

  const Form = React.lazy(async () => {
    return await import(`pages/${name}/Form/Form`).catch(() => ({
      default: () => <ErrorPage title="Страница не найдена" />,
    }))
  })

  return [
    {
      path: Core[name].url,
      element: (
        <React.Suspense>
          <Archive />
        </React.Suspense>
      ),
    },
    {
      path: Core[name].url + '/create',
      element: (
        <React.Suspense>
          <Form />
        </React.Suspense>
      ),
    },
    {
      path: Core[name].url + '/update/:id',
      element: (
        <React.Suspense>
          <Form />
        </React.Suspense>
      ),
    },
  ]
})

const root = {
  root: {
    path: '/',
    element: <Layout />,
    children: [
      ...routes.flat(),
      { path: '/settings', element: <SettingsPage /> },
      { path: '/reports', element: <ReportsPage /> },
      { path: '/inventory-parks', element: <InventoryParkPage /> },
      { path: '/inventory-parks/:id', element: <InventoryHistoryPage /> },
      { path: '*', element: <ErrorPage title="Страница не найдена" /> },
    ],
  },
}

export default createBrowserRouter(Object.keys(root).map((key) => root[key]))
