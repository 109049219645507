import React from 'react'
import styles from './FormControl.module.scss'
import { LabelProps } from './types'

export default function Label({ text, style }: LabelProps) {
  return (
    <span className={styles.label} style={{ fontWeight: style || 'normal' }}>
      {text}
    </span>
  )
}
