import React from 'react'
import styles from './Loop.module.scss'
import classNames from 'classnames'
import { LoopProps } from './types'
import { Button, ButtonPlus } from 'ui'

export default function Loop({
  button = { text: 'Добавить', type: 'DEFAULT' },
  component,
  error,
  gap,
  handleAdd,
  handleDelete,
  items,
  name,
  title,
}: LoopProps) {
  const props = typeof component === 'function' ? {} : component.props
  const Component =
    typeof component === 'function' ? component : component.render

  const gapY = 'mt_' + gap.y.toLowerCase()

  return (
    <div>
      {items.map((_, i) => (
        <div key={i} className={classNames(styles.component, styles[gapY])}>
          <Component
            index={i}
            name={`${name}[${i}]`}
            onDelete={() => handleDelete(i)}
            {...props}
          />
        </div>
      ))}
      {button.type === 'DEFAULT' && (
        <Button
          component="div"
          theme="OUTLINE"
          text={button.text}
          onClick={handleAdd}
          className={classNames(styles.button, styles[gapY])}
        />
      )}
      {button.type === 'PLUS' && (
        <ButtonPlus
          onClick={handleAdd}
          className={classNames(styles.plus, styles[gapY])}
        />
      )}
      {error && (
        <div className={classNames(styles.error, styles[gapY])}>{error}</div>
      )}
    </div>
  )
}
