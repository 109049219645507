import React from 'react'
import { HandySvg } from 'handy-svg'
import { modifiedClassName } from 'utils'
import styles from './SliderArrow.module.scss'
import { SliderArrowProps } from './types'

import iconSliderArrow from 'assets/svg/slider-arrow.svg'
import classNames from 'classnames'

export default function SliderArrow({
  className,
  direction,
  slider,
}: SliderArrowProps) {
  function handleClick() {
    direction === 'prev' ? slider?.slidePrev() : slider?.slideNext()
  }

  return (
    <div
      className={classNames(
        modifiedClassName(styles, 'container', direction),
        className
      )}
      onClick={handleClick}
    >
      <HandySvg src={iconSliderArrow} />
    </div>
  )
}
