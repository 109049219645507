import React from 'react'
import { HandySvg } from 'handy-svg'
import { Button, Droplist } from 'ui'
import styles from './Tools.module.scss'
import { ArchiveContext } from '../../context'
import { flattenObject } from 'utils'

import iconChevron from 'assets/svg/chevron.svg'

const SelectContext = React.createContext(null)

export default function Select({
  children,
  multiple = false,
  name,
  label,
}: SelectProps) {
  const [opened, setOpened] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = React.useState<'auto' | number>(
    'auto'
  )

  React.useEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth)
  }, [])

  return (
    <div
      ref={containerRef}
      className={styles.select}
      style={{ width: containerWidth }}
    >
      <Button component="div" onClick={() => setOpened(!opened)} theme="STROKE">
        <div
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {label}
        </div>
        <HandySvg src={iconChevron} />
      </Button>
      <Droplist
        opened={opened}
        setOpened={setOpened}
        containerRef={containerRef}
        className={styles.droplist}
      >
        <SelectContext.Provider value={{ name, multiple }}>
          {children}
        </SelectContext.Provider>
      </Droplist>
    </div>
  )
}

Select.Option = function Option({ children, value = undefined }: OptionProps) {
  const { params, setParams } = React.useContext(ArchiveContext)
  const { name, multiple } = React.useContext(SelectContext)

  function handleClick() {
    setParams((prev: any) => {
      if (!multiple) {
        return flattenObject.set(name, value, prev)
      }

      if (value) {
        const old = flattenObject.get(name, prev) || []
        const updated = old.indexOf(value)
          ? [...old, value]
          : old.filter((v: typeof value) => v !== value)

        return flattenObject.set(name, updated, prev)
      }

      return flattenObject.set(name, undefined, prev)
    })
  }

  function check() {
    const selected = flattenObject.get(name, params)

    if (multiple) {
      return selected ? selected.indexOf(value) !== -1 : selected === value
    }

    return selected === value
  }

  return (
    <Droplist.Option onClick={handleClick} highlighted={check()}>
      {children}
    </Droplist.Option>
  )
}

export type SelectProps = {
  children: React.ReactNode
  multiple?: boolean
  name: string
  label: string
}

type OptionProps = {
  children: React.ReactNode
  value?: any
}
