import {
  ComponentPropsWithoutRef,
  ComponentType,
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react'

type LongPressProps<C extends ComponentType> = {
  as: C
  onLongPress?: () => void
  onShortPress?: () => void
  longPressDuration?: number
  onMouseDown?: MouseEventHandler
  onMouseUp?: MouseEventHandler
  onTouchStart?: TouchEventHandler
  onTouchEnd?: TouchEventHandler
} & ComponentPropsWithoutRef<C>

export default function LongPress<C extends ComponentType>({
  as: Component,
  onLongPress,
  onShortPress,
  longPressDuration = 1000,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  ...props
}: LongPressProps<C>) {
  const timerId = useRef<ReturnType<typeof setTimeout>>(null)
  const timeoutRef = useRef<boolean>(false)

  const handleStart = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      timerId.current = setTimeout(() => {
        timeoutRef.current = true
      }, longPressDuration)

      if (event.type === 'mousedown') {
        onMouseDown?.(event)
      } else if (event.type === 'touchstart') {
        onTouchStart?.(event)
      }
    },
    [longPressDuration, onMouseDown, onTouchStart]
  )

  const handleEnd = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      clearInterval(timerId.current)

      if (timeoutRef.current === true) {
        onLongPress?.()
      } else {
        onShortPress?.()
      }

      if (event.type === 'mouseup') {
        onMouseUp?.(event)
      } else if (event.type === 'touchend') {
        onTouchEnd?.(event)
      }

      timeoutRef.current = false
    },
    [onLongPress, onShortPress, onMouseUp, onTouchEnd]
  )

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current)
    }
  }, [])

  return (
    // @ts-ignore
    <Component
      {...props}
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
    />
  )
}
