import React from 'react'
import styles from './Gallery.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FilesApi } from 'core'
import { SliderArrow, Media } from 'ui'
import { modifiedClassName } from 'utils'
import { Swiper as TSwiper } from 'swiper/swiper'

export default function Gallery({ gallery }: GalleryProps) {
  const { activeSlide, images, onSlideChange } = gallery
  const [slider, setSlider] = React.useState<TSwiper>(null)

  const arrows = images.length > 1 ? ['prev', 'next'] : null

  React.useEffect(() => {
    slider?.slideTo(activeSlide)
  }, [activeSlide])

  const media = images.map((src) =>
    src.includes('youtube:') ? src : FilesApi.loadFile(src)
  )

  return (
    <React.Fragment>
      <Swiper
        slidesPerView={1}
        className={styles.slider}
        onBeforeInit={setSlider}
        onSlideChange={
          onSlideChange ? (e) => onSlideChange(e.activeIndex) : undefined
        }
      >
        {arrows?.map((direction) => (
          <SliderArrow
            key={direction}
            direction={direction as 'prev' | 'next'}
            slider={slider}
            className={modifiedClassName(styles, 'slider__arrow', direction)}
          />
        ))}
        {media.length > 0 ? (
          media.map((src) => (
            <SwiperSlide key={src}>
              <Media
                open={media}
                component="div"
                className={styles.image}
                src={src}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url('/images/image.jpg')`,
                pointerEvents: 'none',
              }}
            ></div>
          </SwiperSlide>
        )}
      </Swiper>
    </React.Fragment>
  )
}

export type GalleryProps = {
  gallery: {
    activeSlide?: number
    images: string[]
    onSlideChange?: (props: any) => any
  }
}
