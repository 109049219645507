import iconMale from 'assets/svg/male.svg'
import iconFemale from 'assets/svg/female.svg'
import iconMaleFemale from 'assets/svg/male-female.svg'

export default function useGender(gender: TGender | TGender[]) {
  const getValue = (gender: TGender) =>
    gender === 'MALE' ? 'Мужской' : 'Женский'

  const getIcon = (gender: TGender | TGender[]) =>
    typeof gender === 'string'
      ? gender === 'MALE'
        ? iconMale
        : iconFemale
      : gender.length === 1
      ? getIcon(gender[0])
      : iconMaleFemale

  const value =
    typeof gender === 'string'
      ? [getValue(gender)]
      : gender.map((g) => getValue(g))

  const avatar =
    typeof gender === 'string' &&
    '/images/user-' + gender.toLowerCase() + '.jpg'

  const icon = getIcon(gender)

  const fl = value.map((g) => g[0].toLowerCase())

  return { value, icon, fl, avatar }
}

type TGender = 'MALE' | 'FEMALE'
