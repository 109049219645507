import React from 'react'
import { FilesApi } from 'core'
import styles from './Header.module.scss'
import { Droplist } from 'ui'
import { useAuthStore } from 'store'
import { LongPress } from 'components'
// import image from 'public/'

export default function User() {
  const { parks, user, togglePark } = useAuthStore()

  const isActor = user.role === 'ANIMATOR' || user.role === 'SHOWMAN'
  const selectedParks = parks.filter(({ selected }) => selected)
  const isAllSelected = selectedParks.length === parks.length

  const [droplistVisible, setDroplistVisible] = React.useState(false)
  const droplistContainerRef = React.useRef<HTMLDivElement>(null)

  return (
    user && (
      <React.Fragment>
        <img
          src={
            user.avatar
              ? FilesApi.loadFile(user.avatar)
              : `/images/user-${user.gender?.toLowerCase() || 'male'}.jpg`
          }
          className={styles.avatar}
          alt=""
        />
        <div className={styles.userstate}>
          <div>{`${user.firstName} ${user.lastName}`}</div>

          {parks && !isActor && (
            <div
              className={styles.parks}
              ref={droplistContainerRef}
              onClick={() => parks.length > 0 && setDroplistVisible(true)}
            >
              {isAllSelected
                ? 'Все парки'
                : `(${selectedParks.length}) ` +
                  selectedParks.map((park) => park.title).join(', ')}
              <Droplist
                className={styles.parkList}
                containerRef={droplistContainerRef}
                opened={droplistVisible}
                setOpened={setDroplistVisible}
              >
                <Droplist.Option
                  onClick={() => togglePark(null)}
                  highlighted={isAllSelected}
                >
                  Все парки
                </Droplist.Option>
                {parks.map(({ title, id }) => (
                  <LongPress
                    key={id}
                    as={Droplist.Option}
                    // @ts-ignore
                    onLongPress={() => togglePark(id, true)}
                    onShortPress={() => togglePark(id)}
                    highlighted={parks.find((park) => id === park.id).selected}
                  >
                    {title}
                  </LongPress>
                ))}
              </Droplist>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  )
}
