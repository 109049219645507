import React from 'react'
import { FormControl } from 'components'
import { flattenObject } from 'utils'
import { FormContext } from './Form'
import { ControlProps } from './types'
import { FilesApi } from 'core'
import { FileUpload, Input, Select } from 'ui'

import { FormControlRoots } from 'components/FormControl/types'

export default function Control<C extends FormControlRoots>({
  component,
  name,
  label,
  onInit,
  ...props
}: ControlProps<C>) {
  const context = React.useContext(FormContext)
  const { errors, formData, init, setFormData } = context

  const [options, setOptions] = React.useState(props.options)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const value = flattenObject.get(name, formData)
  const error = errors && errors.find(({ param }) => param === name)

  function checkOption(option) {
    return props.multiple
      ? value.indexOf(option.value) !== -1
      : option.value === value
  }

  React.useEffect(() => {
    if (!loaded) {
      onInit && onInit(value)
      props.onChange && !onInit && props.onChange(value)

      if (component === Select && init) {
        let options = props.lazy
          ? props.multiple
            ? value?.map((key) => {
                return {
                  text: flattenObject.get(`${props.lazy.textKey}`, key),
                  value: flattenObject.get(`${props.lazy.valueKey}`, key),
                  selected: true,
                }
              })
            : [
                {
                  text: flattenObject.get(
                    `${props.lazyRef}.${props.lazy.textKey}`,
                    formData
                  ),
                  value,
                  selected: true,
                },
              ]
          : props.options.map((option) => ({
              ...option,
              selected: checkOption(option),
            }))

        // @ts-ignore
        setOptions(options)
      }

      setLoaded(true)
    }
  }, [value, init])

  async function handleChange(data: any) {
    let value = data

    props.onChange && props.onChange(data)

    if (component === FileUpload) {
      const src = URL.createObjectURL(value)
      // const src = URL.createObjectURL(value[0])
      props.onChange({ src, uploaded: false })

      const formData = new FormData()
      // formData.append('file', value[0])
      formData.append('file', value)

      await FilesApi.uploadFiles(props.folder, formData)
        .then(({ data }) => (value = data.path))
        .then(() => props.onChange({ src, uploaded: true }))
    }

    if (component === Select) {
      value = data.selected
    }

    setFormData((prev) => flattenObject.set(name, value, prev))
  }

  return (
    //@ts-ignore
    <FormControl
      {...props}
      component={component}
      label={label}
      loaded={loaded}
      value={value || ''}
      onChange={handleChange}
      options={options}
      error={error && error.msg}
    />
  )
}
