import React from 'react'
import styles from './Catalog.module.scss'
import { Card } from './components'

export default function Catalog({ children }: CatalogProps) {
  return <div className={styles.container}>{children}</div>
}

Catalog.Card = Card

type CatalogProps = {
  children: React.ReactNode
}
