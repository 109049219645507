import React from 'react'
import { HandySvg } from 'handy-svg'
import styles from './Header.module.scss'

export default function Header({ title, items = [] }: HeaderProps) {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      {items.length > 0 && (
        <div className={styles.info}>
          {items.map(({ icon, text, color = 'inherit' }, i) => (
            <div key={i} className={styles.item} style={{ color }}>
              {icon && <HandySvg src={icon} />}
              {text}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type HeaderProps = {
  title: string
  items?: {
    color?: string
    text: string
    icon?: string
  }[]
}
