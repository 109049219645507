import { axios, Core } from 'core'
import { useAlertStore } from 'store'

export default function useLogin() {
  const alert = useAlertStore()
  async function login(data: { phone: string; password: string }) {
    alert.loading({ title: 'Загрузка пользователя...' })

    return await axios.post<LoginResponse>('/auth/login', data)
  }

  return { login }
}

type LoginResponse = {
  capabilities: Core.TPermission[]
  parks: Core.TPark[]
  role: string
  token: string
  user: Core.TUser
}
