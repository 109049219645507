import Model from 'core/Model'
import { TPark } from '../Park/Park'

const programRateSchema = {
  id: NaN,
  title: '',
  programTypeKey: '',
  typeKey: '',
  price: [],
  step: NaN,
  clientPrice: NaN,
  actorPrice: NaN,
}

export const ProgramRate = new Model<TProgramRate>({
  url: '/program-rates',
  schema: programRateSchema,
  labels: {
    archive: 'Тарифы программ',
    create: 'Добавить тариф',
    search: 'Поиск по тарифам',
    creating: 'Добавление тарифа программ',
    updating: 'Редактирование тарифа программ',
    menuText: 'Тарифы программ',
  },
})

type TRatePrice = {
  id: number
  clients?: TPark[]
  type: 'PER_HOUR' | 'PER_PERSON'
  rateId: number
  value: number
  minValue: number
  clientPrice: number
  actorPrice: number
}

export type TProgramRate = Omit<typeof programRateSchema, 'price'> & {
  price?: TRatePrice[]
}
