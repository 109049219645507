import React from 'react'
import { flattenObject } from 'utils'
import { FormContext } from './Form'
import { Gallery } from './components'
import { GalleryProps } from './components/Gallery/types'

export default function ContainerGallery({
  className,
  name,
  folder,
}: ContainerGalleryProps) {
  const { errors, formData, setFormData } = React.useContext(FormContext)
  const error = errors && errors.find(({ path }) => path === name)

  return (
    <Gallery
      className={className}
      folder={folder}
      error={error && error.message}
      initial={flattenObject.get(name, formData)}
      name={name}
      setFormData={setFormData}
    />
  )
}

type ContainerGalleryProps = Omit<
  GalleryProps,
  'initial' | 'error' | 'setFormData'
>
