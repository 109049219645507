import Api from 'core/Api'
import Model from 'core/Model'
import { TInventoryApproval } from '../InventoryApproval/InventoryApproval'
import { TInventoryCategory } from '../InventoryCategory/InventoryCategory'
import { TInventoryStatus } from '../InventoryStatus/InventoryStatus'
import { TInventoryType } from '../InventoryType/InventoryType'

const inventorySchema = {
  id: NaN,
  title: '',
  size: '',
  gender: [],
  equipment: [],
  gallery: [],
  comment: '',
  approvalKey: '',
  statusKey: '',
  typeKey: '',
}

class InverntoryApi<TInventory> extends Api<TInventory> {
  shedule(params) {
    return this.instance.get(`${this.url}/shedule`, { params })
  }

  pack({ inventoryId, parkId }): any {
    return this.instance.post(`${this.url}/shedule/pack`, {
      inventoryId,
      parkId,
    })
  }

  delivery(id): any {
    return this.instance.patch(`${this.url}/shedule/${id}/delivery`)
  }

  return(id): any {
    return this.instance.patch(`${this.url}/shedule/${id}/return`)
  }
}

export const Inventory = new Model<TInventory, InverntoryApi<TInventory>>({
  Api: InverntoryApi,
  url: '/inventories',
  schema: inventorySchema,
  labels: {
    archive: 'Инвентарь',
    create: 'Добавить инвентарь',
    search: 'Поиск по инвентарю',
    creating: 'Добавление инвентаря',
    updating: 'Редактирование инвентаря',
    menuText: 'Инвентарь',
  },
})

export type TInventoryEquipment = {
  title: string
  count: number
  image: string
}

export type TInventory = Omit<typeof inventorySchema, 'equepment'> & {
  equipment: TInventoryEquipment[]
  approval?: TInventoryApproval
  categories?: TInventoryCategory[]
  restavrations?: { start: Date; end: Date }[]
  status?: TInventoryStatus
  type?: TInventoryType
}
