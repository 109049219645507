import React from 'react'
import styles from './Pagination.module.scss'
import { ArchiveContext } from '../../context'
import ReactPaginate from 'react-paginate'
import { useWindowDimensions } from 'hooks'

export default function Pagination() {
  const { count, params, setParams, $content } =
    React.useContext(ArchiveContext)
  const { width } = useWindowDimensions()

  function handleChange({ selected }: { selected: number }) {
    setParams((prev: any) => ({ ...prev, offset: prev.limit * selected }))
    $content.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    params && (
      <ReactPaginate
        className={styles.container}
        breakLabel="..."
        nextLabel=">"
        onPageChange={handleChange}
        pageRangeDisplayed={width > 600 ? 2 : 1}
        marginPagesDisplayed={width > 600 ? 3 : 1}
        pageCount={Math.ceil(count / params.limit)}
        previousLabel="<"
        forcePage={params.offset / params.limit || 0}
        renderOnZeroPageCount={null}
      />
    )
  )
}
