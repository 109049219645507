import {
  InventoryHistoryType,
  InventorySheduleListItemType,
} from 'api/endpoints'
import { useMemo, useReducer } from 'react'

function reducer(
  state: InventorySheduleListItemType[],
  dispatch: (action: any) => InventorySheduleListItemType[]
) {
  return dispatch(state)
}

function initInventorySheduleList(payload: InventorySheduleListItemType[]) {
  return (store: InventorySheduleListItemType[]) => {
    return payload
  }
}

function createHistory(payload: InventoryHistoryType) {
  return (state: InventorySheduleListItemType[]) => {
    return state.map((item) => {
      if (item.inventory_id === payload.inventory_id) {
        item.last_history = payload
      }

      return item
    })
  }
}

export default function useInventoryScheduleApi(): [
  schedule: InventorySheduleListItemType[],
  api: {
    init: (list: InventorySheduleListItemType[]) => void
    createHistory: (history: InventoryHistoryType) => void
  }
] {
  const [schedule, dispatch] = useReducer(reducer, [])

  const api = useMemo(() => {
    return {
      init: (list: InventorySheduleListItemType[]) =>
        dispatch(initInventorySheduleList(list)),
      createHistory: (history: InventoryHistoryType) =>
        dispatch(createHistory(history)),
    }
  }, [])

  return [schedule, api]
}
