import React from 'react'

export default function useImage(
  src: string,
  plug: string = '/images/image.jpg',
  props?: React.ImgHTMLAttributes<HTMLImageElement>
) {
  const [image, setImage] = React.useState(src ? src : plug)

  React.useEffect(() => setImage(src ? src : plug), [src])

  return () => <img src={image} {...props} />
}
