import { create } from 'zustand'
import { Button } from 'ui'

export const useAlertStore = create<AlertStore>((set, store) => ({
  state: null,
  clear: () => {
    set((store) => ({ ...store, state: null }))
  },
  confirm: (props) => {
    const state = alert(props, 'CONFIRM')
    set((store) => ({ ...store, state }))
  },
  error: (props) => {
    const state = alert(props, 'ERROR')
    set((store) => ({ ...store, state }))
  },
  loading: (props) => {
    const state = alert(props, 'LOADING')
    set((store) => ({ ...store, state }))
  },
  success: (props) => {
    const state = alert(props, 'SUCCESS')
    set((store) => ({ ...store, state }))
  },
}))

function alert(props: AlertProps, theme: Theme): AlertState {
  return { ...props, theme }
}

type Theme = 'ERROR' | 'LOADING' | 'SUCCESS' | 'CONFIRM'

interface AlertProps {
  title: string
  subtitle?: string
  buttons?: React.ComponentProps<typeof Button>[]
}

interface AlertState extends AlertProps {
  theme: Theme
}

type AlertStore = {
  state: AlertState
  clear: () => void
  confirm: (props: AlertProps) => void
  error: (props: AlertProps) => void
  loading: (props: AlertProps) => void
  success: (props: AlertProps) => void
}
