import { AxiosInstance } from 'axios'
import axios from './axios'
class Api<T extends any> {
  url: string
  instance: AxiosInstance

  constructor(url: string) {
    this.url = url
    this.instance = axios
  }

  init(instance: AxiosInstance) {
    this.instance = instance
  }

  async create(data: any, params: any = {}) {
    const url = this.url
    return await this.instance.post<T>(url, data, params)
  }

  async findAll<R>(params: any = {}) {
    const url = this.url
    return await this.instance
      .get<R extends unknown ? { rows: T[]; count: number } : R>(url, {
        params,
      })
      .then(({ data }) => data)
  }

  async formData(id: number) {
    const url = [this.url, 'form-data', id].join('/')
    return await this.instance.get<T>(url).then(({ data }) => data)
  }

  async findOne(id: number, params: any = {}) {
    const url = [this.url, id].join('/')
    return await this.instance
      .get<T>(url, {
        params: { ...params, string: JSON.stringify(params) },
      })
      .then(({ data }) => data)
  }

  async update(id: number, data: any, params: any = {}) {
    const url = [this.url, id].join('/')
    return await this.instance.put(url, data, params)
  }

  async delete(id: number, params: any = {}) {
    const url = [this.url, id].join('/')
    return await this.instance.delete(url, { params })
  }
}

export default Api
