import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Core } from 'core'
import { Button, Page, Catalog, Table } from 'ui'
import { ArchiveContext } from './context'
import { Pagination, Settings, Tools } from './components'

const models = Object.keys(Core).map((key) => Core[key])

export default function Archive({
  children,
  count,
  params,
  setParams,
  ...props
}: ArchiveProps) {
  const { pathname } = useLocation()
  const [model] = React.useState(
    props.model && models.find(({ url }) => url === pathname)
  )
  const showCreateButton = model && model.capabilities.create

  const $content = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!isNaN(count) && !params.limit) {
      setParams((prev) => ({ ...prev, limit: 12 }))
    }
    if (!isNaN(count) && !params.offset) {
      setParams((prev) => ({ ...prev, offset: 0 }))
    }
  }, [])

  return (
    <Page {...props}>
      {model && (
        <Page.Header title={model.labels.archive}>
          {showCreateButton && (
            <Button
              component={Link}
              text={model.labels.create}
              theme="OUTLINE"
              to={model.url + '/create'}
              style={{ whiteSpace: 'nowrap' }}
            />
          )}
        </Page.Header>
      )}
      <ArchiveContext.Provider
        value={{ count, model: props.model, params, setParams, $content }}
      >
        <span ref={$content}></span>
        {children}
        <Pagination />
      </ArchiveContext.Provider>
    </Page>
  )
}

Archive.Catalog = Catalog
Archive.Settings = Settings
Archive.Header = Page.Header
Archive.Table = Table
Archive.Tools = Tools

export type ArchiveProps = React.ComponentProps<typeof Page> & {
  model?: keyof typeof Core
  count?: number
  params?: any
  setParams?: React.Dispatch<any>
}
