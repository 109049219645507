import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v2',
  // withCredentials: true,
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
})

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const message = error.response?.data?.message

    alert(message || 'Непредвиденная ошибка')

    return Promise.reject(error)
  }
)

export default instance
