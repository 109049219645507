import React from 'react'
import classNames from 'classnames'
import styles from './FormControl.module.scss'
import Label from './Label'
import { InputProps } from 'ui/Input/types'
import { FileUpload, Input } from 'ui'

import { FormControlProps, FormControlRoots } from './types'

export default function FormControl<
  T extends FormControlRoots = React.FunctionComponent<InputProps>
>({
  component: Component,
  className,
  error,
  label,
  loaded = true,
  onChange,
  style,
  ...props
}: FormControlProps<T>) {
  function handleChange(event: any) {
    if (Component === Input) {
      return onChange(event.target.value)
    }

    return onChange(event)
  }

  return (
    <label className={classNames(styles.container, className)} style={style}>
      {label && <Label text={label.text} style={label.style} />}
      {loaded ? (
        // @ts-ignore
        <Component {...props} onChange={handleChange} />
      ) : (
        <div className={styles.loaded}>Загрузка</div>
      )}
      {error && <span className={styles.error}>{error}</span>}
    </label>
  )
}
