import React from 'react'
import { axios } from 'core'
import { useAlertStore } from 'store'
import { useCookies } from 'react-cookie'

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export default function useAxiosWithIntersceptors() {
  const alert = useAlertStore()
  const [, setCookie] = useCookies(['token'])

  React.useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (response.headers.token) {
          setCookie('token', response.headers.token)
        }

        return response
      },
      (error) => {
        console.log(error)

        const { data } = error.response

        alert.error({
          title: data.title || 'Ошибка',
          subtitle: data.message,
          buttons: [{ text: 'Ок', onClick: alert.clear }],
        })

        return Promise.reject(error)
      }
    )

    axios.interceptors.request.use((req) => {
      const token = getCookie('token')

      if (token) {
        req.headers.authorization = `Bearer ${token}`
      }

      return req
    })
  }, [])
}
