import React from 'react'
import classNames from 'classnames'
import { HandySvg } from 'handy-svg'
import { Droplist } from 'ui'
import styles from './Price.module.scss'
import iconChevron from 'assets/svg/chevron.svg'

export default function List({ rates, selected, onChange }: ListProps) {
  const droplistRef = React.useRef<HTMLDivElement>(null)
  const [listVisible, setListVisible] = React.useState(false)

  return (
    <div
      ref={droplistRef}
      className={styles.rates}
      onClick={() => setListVisible(!listVisible)}
    >
      <div
        className={classNames(styles.current, rates?.length > 1 && styles.drop)}
      >
        {rates?.length > 1 && <HandySvg src={iconChevron} />}
        {selected.title}
      </div>
      <Droplist
        containerRef={droplistRef}
        opened={listVisible}
        setOpened={setListVisible}
      >
        {rates
          // @ts-ignore
          .sort((a, b) => +a.value - +b.value)
          .map((rate, i) => (
            <Droplist.Option
              key={i}
              highlighted={selected.id === rate.id}
              onClick={() => onChange(rate)}
            >
              {rate.title}
            </Droplist.Option>
          ))}
      </Droplist>
    </div>
  )
}

type ListProps = {
  rates: Rate[]
  selected: Rate
  onChange: (rate: Rate) => void
}

export type Rate = {
  id: number
  cost: string
  title: string
  selected?: boolean
}
