import { create } from 'zustand'

const partyProgramComponents = {
  Table: 'Таблица',
  Cards: 'Карточки',
}

const programPriceTypes = {
  Act: 'Стоимость по акту',
  Client: 'Стоимость для клиента',
  Salary: 'Зарплата исполнителя',
}

const getComponent = (key: string) => ({
  value: key,
  title: partyProgramComponents[key],
})

const getPriceType = (key: string) => ({
  value: key,
  title: programPriceTypes[key],
})

const partyProgramComponent = getComponent(
  localStorage.getItem('partyProgramComponent') || 'Table'
)

const programPriceType = getPriceType(
  localStorage.getItem('programPriceType') || 'Act'
)

export const useSettingsStore = create<SettingsStore>((set) => ({
  partyProgramComponents,
  partyProgramComponent,
  setPartyProgramComponent: (key) => {
    localStorage.setItem('partyProgramComponent', key)
    return set((state) => ({
      ...state,
      partyProgramComponent: getComponent(key),
    }))
  },
  programPriceTypes,
  programPriceType,
  setProgramPriceType: (key) => {
    localStorage.setItem('programPriceType', key)
    return set((state) => ({
      ...state,
      programPriceType: getPriceType(key),
    }))
  },
}))

type SettingsStore = {
  partyProgramComponent: { title: string; value: string }
  partyProgramComponents: typeof partyProgramComponents
  setPartyProgramComponent: (value: string) => void
  programPriceType: { title: string; value: string }
  programPriceTypes: typeof programPriceTypes
  setProgramPriceType: (value: string) => void
}
