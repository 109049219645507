import Model from 'core/Model'

const programStatusSchema = {
  id: NaN,
  key: '',
  color: '',
  title: '',
}

export const ProgramStatus = new Model<TProgramStatus>({
  url: '/program-statuses',
  schema: programStatusSchema,
  labels: {
    archive: '',
    create: '',
    creating: '',
    updating: '',
  },
})

export type TProgramStatus = typeof programStatusSchema
