import { create } from 'zustand'
import { Core } from 'core'

export const useAuthStore = create<AuthStore>((set) => ({
  parks: [],
  user: null,
  role: null,
  init: (data) => {
    const storageParks =
      localStorage.getItem('selectedParks') || JSON.stringify([])

    const selectedParks = JSON.parse(storageParks)

    // @ts-ignore
    return set((store) => ({
      ...store,
      ...data,
      // @ts-ignore
      role: data.role,
      parks: data.parks.map((park) => ({
        ...park,
        selected:
          selectedParks.length === 0
            ? true
            : selectedParks.indexOf(park.id) !== -1,
      })),
    }))
  },
  togglePark(id, single = false) {
    set(({ parks }) => {
      const { selected } = parks.find((park) => park.id === id) || {}
      if (selected && parks.filter(({ selected }) => selected).length === 1) {
        return { parks }
      } else if (!id) {
        parks = parks.map((park) => ({ ...park, selected: true }))
        // @ts-ignore
      } else if (!window.event?.ctrlKey && !single) {
        parks = parks.map((park) =>
          park.id === id ? { ...park, selected: !selected } : park
        )
      } else {
        parks = parks.map((park) => ({ ...park, selected: park.id === id }))
      }

      localStorage.setItem(
        'selectedParks',
        JSON.stringify(
          parks.filter(({ selected }) => selected).map(({ id }) => id)
        )
      )

      return { parks }
    })
  },
}))

type AuthStore = {
  parks: (Core.TPark & { selected?: boolean })[]
  user: Core.TUser
  role:
    | 'DEVELOPER'
    | 'SUDO'
    | 'ANIMATOR'
    | 'SHOWMAN'
    | 'MANAGER'
    | 'EDITOR'
    | 'DRESSER'
  init: ({ user, parks }: InitProps) => void
  togglePark: (id: number) => void
}

type InitProps = {
  user: Core.TUser
  parks: Core.TPark[]
}
