import classNames from 'classnames'
import React from 'react'
import { Droplist, DroplistProps } from 'ui'
import { Icon } from 'ui/Icon'
import styles from './Settings.module.scss'

const SettingsContext = React.createContext(null)

export default function Settings({
  children,
  className,
  fallback = 'Нет доступных настроек',
}: SettingsProps) {
  const [count, setCount] = React.useState<number>(
    Array.isArray(children) ? children.length : children ? 1 : 0
  )

  const containerRef = React.useRef<HTMLDivElement>()
  const [opened, setOpened] = React.useState(false)

  if (count < 1 && fallback) {
    return <React.Fragment>{fallback}</React.Fragment>
  }

  if (count < 1) {
    return null
  }

  return (
    <div ref={containerRef} className={classNames(styles.container, className)}>
      <button className={styles.button} onClick={() => setOpened(!opened)}>
        <Icon name="settings" />
      </button>
      <Droplist opened={opened} setOpened={setOpened} containerRef={containerRef} className={styles.droplist}>
        <SettingsContext.Provider value={{ setCount }}>{children}</SettingsContext.Provider>
      </Droplist>
    </div>
  )
}

Settings.Option = function Option({ show = true, ...props }: OptionProps) {
  const { setCount } = React.useContext(SettingsContext)

  React.useEffect(() => {
    if (!show) setCount((prev: number) => prev - 1)
  }, [])

  if (!show) {
    return null
  }

  return <Droplist.Option {...props} />
}

type OptionProps = React.ComponentProps<typeof Droplist.Option> & {
  show?: boolean
}

export type SettingsProps = Pick<DroplistProps, 'className' | 'children'> & {
  fallback?: React.ReactNode
}
