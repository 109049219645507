import React from 'react'
import styles from './Price.module.scss'
import List, { Rate } from './List'
import Value from './Value'

const getSelected = (rates: Rate[]) =>
  // @ts-ignore
  rates?.sort((a, b) => +a.value - +b.value).find(({ selected }) => selected) ||
  rates[0]

export default function Price({
  rates,
  calculating,
  cost,
  style,
  onChange,
}: PriceProps) {
  const [selected, setSelected] = React.useState<Rate>(getSelected(rates))
  // React.useEffect(() => setSelected(getSelected(rates)), [rates])
  React.useEffect(() => onChange && onChange(selected), [selected])

  function handleChange(rate: Rate) {
    setSelected(rate)
  }

  return (
    <div className={styles.container} style={style}>
      <List rates={rates} selected={selected} onChange={handleChange} />
      <Value calculating={calculating} value={cost || selected.cost} />
    </div>
  )
}

type PriceProps = {
  rates: Rate[]
  calculating?: boolean
  cost?: string
  style?: React.CSSProperties
  onChange?: (props: Rate) => void
}
