import React from 'react'
import { useChangeProps } from '../types'

export default function useChange({
  onChange,
  options,
  event,
}: useChangeProps) {
  React.useEffect(() => {
    if (onChange) {
      onChange(event)
    }
  }, [options])
}
