import classNames from 'classnames'

export const modifiedClassName = (
  styles: any,
  key: string,
  variants: string | string[]
): string => {
  const modificators = typeof variants === 'string' ? [variants] : variants
  return classNames(
    styles[key],
    modificators
      .map((modificator) => styles[[key, modificator].join('_').toLowerCase()])
      .join(' ')
  )
}
