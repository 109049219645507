import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Droplist.module.scss'
import classNames from 'classnames'
import { useDocumentClick } from './hooks'

import { UseDocumentClickOptions } from './hooks/useDocumentClick'

export default function Droplist({
  children,
  className,
  opened,
  ...useDocumentClickOptions
}: DroplistProps) {
  useDocumentClick({ ...useDocumentClickOptions, opened })

  return (
    <div
      className={classNames(styles.container, className, opened && '--opened')}
    >
      {children}
    </div>
  )
}

Droplist.Option = function Option<C extends OptionRoots = 'div'>({
  // @ts-ignore
  Component = 'div',
  children,
  className,
  highlighted,
  ...props
}: OptionProps<C>) {
  return React.createElement(
    Component,
    {
      ...props,
      className: classNames(
        styles.item,
        className,
        highlighted && '--highlighted'
      ),
    },
    children
  )
}

export type DroplistProps = UseDocumentClickOptions & {
  className?: string
  children: React.ReactNode
}

type OptionRoots = keyof JSX.IntrinsicElements | React.ComponentType<any>

type OptionProps<C extends OptionRoots = 'div'> = GetPropsWithOverride<
  {
    Component?: C
    children: React.ReactNode
    highlighted?: boolean
  },
  C
> & {
  className?: string
}

type GetPropsWithOverride<Props, C extends OptionRoots = 'div'> = Props &
  Omit<React.ComponentProps<C>, keyof Props>
