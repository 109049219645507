import React from 'react'
import styles from './Comment.module.scss'
import { modifiedClassName } from 'utils'

export default function Comment({ text, variant = 'COMMENT' }: CommentProps) {
  return (
    <div
      className={modifiedClassName(styles, 'container', variant.toLowerCase())}
    >
      {text}
    </div>
  )
}

type CommentProps = {
  variant?: 'COMMENT' | 'DESCRIPTION'
  text: string
}
