import React from 'react'
import styles from './Card.module.scss'

export default function Buttons({ children, style }: ButtonsProps) {
  return (
    <div className={styles.buttons} style={style}>
      {children}
    </div>
  )
}

export type ButtonsProps = {
  children: React.ReactNode
  style?: React.CSSProperties
}
