import axios from './axios'

export default class Router {
  pathname: string

  constructor({ pathname }: { pathname: string }) {
    this.pathname = pathname
  }

  private join(url: string) {
    return this.pathname + url
  }

  async get<T>(url: string, ...options: Tail<AxiosGetParametersType>) {
    const { data } = await axios.get<T>(this.join(url), ...options)
    return data
  }

  async post<T>(url: string, ...options: Tail<AxiosPostParametersType>) {
    const { data } = await axios.post<T>(this.join(url), ...options)
    return data
  }

  async put<T>(url: string, ...options: Tail<AxiosPutParametersType>) {
    const { data } = await axios.put<T>(this.join(url), ...options)
    return data
  }

  async patch<T>(url: string, ...options: Tail<AxiosPatchParametersType>) {
    const { data } = await axios.patch<T>(this.join(url), ...options)
    return data
  }

  async delete<T>(url: string, ...options: Tail<AxiosDeleteParametersType>) {
    const { data } = await axios.delete<T>(this.join(url), ...options)
    return data
  }
}

type Tail<T extends unknown[]> = T extends [infer _, ...infer P] ? P : never
type AxiosGetParametersType = Parameters<typeof axios.get>
type AxiosPostParametersType = Parameters<typeof axios.post>
type AxiosPutParametersType = Parameters<typeof axios.put>
type AxiosPatchParametersType = Parameters<typeof axios.patch>
type AxiosDeleteParametersType = Parameters<typeof axios.delete>
