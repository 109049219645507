import classNames from 'classnames'
import React from 'react'
import Loader from 'react-spinner-loader'
import styles from './Spinner.module.scss'

export default function Spinner({ className, show }: SpinnerProps) {
  return (
    <div className={classNames(styles.container, className)}>
      <Loader show={show} className={styles.className} />
    </div>
  )
}

interface SpinnerProps {
  className?: string
  show: boolean
}
