import React from 'react'
import { HandySvg } from 'handy-svg'
import styles from './Alert.module.scss'
import { Button, Spinner } from 'ui'

import iconCheck from 'assets/svg/check.svg'
import iconCross from 'assets/svg/cross.svg'
import { useAlertStore } from 'store'

export default function Alert() {
  const alert = useAlertStore()

  if (!alert.state) {
    return
  }

  const { theme, title, subtitle, buttons } = alert.state

  return (
    <div className={styles.container}>
      {renderIcon(theme)}
      <div className={styles.title}>{title}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map((props, i) => (
            <Button key={i} {...props} size="SMALL" className={styles.button} />
          ))}
        </div>
      )}
    </div>
  )
}

function renderIcon(theme) {
  switch (theme) {
    case 'LOADING':
      return <Spinner show={true} />
    case 'CONFIRM':
      return <Spinner show={true} />
    case 'SUCCESS':
      return (
        <div className={styles.success}>
          <HandySvg src={iconCheck} />
        </div>
      )
    case 'ERROR':
      return (
        <div className={styles.error}>
          <HandySvg src={iconCross} />
        </div>
      )

    default:
      return null
  }
}
