import React from 'react'
import { Link } from 'react-router-dom'
import { Settings } from 'ui'
import { Core } from 'core'
import { ArchiveContext } from '../../context'

export default function Update({ id }: { id: number }) {
  const { model } = React.useContext(ArchiveContext)
  const { url } = Core[model]
  const show = Core[model].capabilities.update === true
  console.log(show)

  return (
    <Settings.Option show={show} Component={Link} to={`${url}/update/${id}`}>
      Редактировать
    </Settings.Option>
  )
}
