import Model from 'core/Model'

const inventoryStatusSchema = {
  id: NaN,
  key: '',
  color: '',
  title: '',
}

export const InventoryStatus = new Model<TInventoryStatus>({
  url: '/inventory-statuses',
  schema: inventoryStatusSchema,
  labels: { archive: '', create: '', creating: '', updating: '' },
})

export type TInventoryStatus = typeof inventoryStatusSchema
