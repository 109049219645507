import React from 'react'

export default function useCondition<C extends string = string>(
  condition: boolean,
  {
    text,
    colors = ['var(--success)', 'var(--danger)', 'var(--warning)'],
  }: Options
) {
  const status = React.useMemo<{ text: string; color: C }>(
    () => ({
      text:
        condition === true ? text[0] : condition === false ? text[1] : text[2],
      // @ts-ignore
      color:
        condition === true
          ? colors[0]
          : condition === false
          ? colors[1]
          : colors[2],
    }),
    [condition]
  )

  return status
}

type Options = {
  text: [string, string, string]
  colors?: [string, string, string]
}
