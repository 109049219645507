import { Core } from 'core'
import React from 'react'
import { flattenObject } from 'utils'
import { useLazyProps } from '../types'

export default function useLazy({ lazy, opened, setOptions }: useLazyProps) {
  const [loaded, setLoaded] = React.useState(!lazy)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    if (!loaded && opened) {
      fetchOptions()
    }
  }, [opened])

  React.useEffect(() => {
    if (lazy) {
      setLoaded(false)
    }
  }, [lazy])

  async function fetchOptions() {
    const { textKey, valueKey, before = [], after = [] } = lazy

    await Core[lazy.model].api
      .findAll(lazy.fetchParams)
      .then((data) => {
        setOptions((prev) => {
          return data.rows.map((instance: any) => {
            const text = flattenObject.get(textKey, instance) || instance.text
            const value =
              flattenObject.get(valueKey, instance) || instance.value
            const selected = prev.find((option) => option.value === value)

            return {
              text,
              value,
              selected: selected && selected.selected,
            }
          })
        })

        return data.rows
      })
      .then((data) => setData(data))
      .then(() => setLoaded(true))
      .catch(({ response }) => response.data)
  }

  return data
}
