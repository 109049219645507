import React from 'react'
import { flattenObject } from 'utils'
import { Button } from 'ui'
import Row from './Row'
import { ArchiveContext } from '../../context'

const TabsContext = React.createContext(null)

export default function Tabs({ children, multiple = false, name }: TabsProps) {
  return (
    <Row>
      <TabsContext.Provider value={{ multiple, name }}>
        {children}
      </TabsContext.Provider>
    </Row>
  )
}

Tabs.Tab = Tab

function Tab({ children, value = undefined }: TabProps) {
  const { params, setParams } = React.useContext(ArchiveContext)
  const { name, multiple } = React.useContext(TabsContext)

  function handleClick() {
    setParams((prev: any) => ({
      ...prev,
      ...flattenObject.set(name, value, prev),
    }))
  }

  function check() {
    return flattenObject.get(name, params) === value
  }

  return (
    <Button theme={check() ? 'PRIMARY' : 'OUTLINE'} onClick={handleClick}>
      {children}
    </Button>
  )
}

type TabsProps = {
  children: React.ReactNode
  multiple?: boolean
  name: string
}

type TabProps = {
  children: React.ReactNode
  value?: any
}
