import Model from 'core/Model'
import { TUser } from '../User/User'

const userRoleSchema = {
  id: NaN,
  title: '',
  permissions: [],
}

export const UserRole = new Model<TUserRole>({
  url: '/user-roles',
  schema: userRoleSchema,
  labels: {
    archive: 'Роли пользователей',
    create: 'Добавить роль',
    search: 'Поиск по ролям',
    creating: 'Добавление роли',
    updating: 'Редактирование роли',
    menuText: 'Роли пользователей',
  },
})

export type TPermission = {
  id: number
  key: string
  routeId: number
  title: string
}

export type TUserRole = Omit<typeof userRoleSchema, 'permissions'> & {
  permissions?: TPermission[]
  users?: TUser[]
  key: 'DEVELOPER' | 'SUDO' | 'ANIMATOR' | 'SHOWMAN' | 'MANAGER' | 'EDITOR'
}
