import React from 'react'
import { FileUploadProps } from './types'

export default React.forwardRef<HTMLInputElement, FileUploadProps>(
  function FileUpload({
    accept = '.jpg,.png',
    children,
    className,
    dragAndDrop = true,
    multiple,
    onChange,
    onClick,
  }) {
    const [dragActive, setDragActive] = React.useState(false)

    const listeners = dragAndDrop
      ? {
          onDragEnter: handleDrag,
          onDragOver: handleDrag,
          onDragLeave: handleDrag,
          onDrop: handleDrop,
        }
      : {}

    function handleDrag(e: React.DragEvent<HTMLLabelElement>) {
      e.preventDefault()
      e.stopPropagation()

      if (e.type === 'dragenter') {
        setDragActive(true)
      } else if (e.type === 'dragover' && dragActive === false) {
        setDragActive(true)
      } else if (e.type === 'dragleave') {
        setDragActive(false)
      }
    }

    function handleDrop(e: React.DragEvent<HTMLLabelElement>) {
      e.preventDefault()
      e.stopPropagation()

      onChange(
        multiple ? Array.from(e.dataTransfer.files) : e.dataTransfer.files[0]
      )
    }

    return (
      <label className={className} {...listeners} onClick={onClick}>
        {children}
        <input
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            onChange(multiple ? Array.from(target.files) : target.files[0])
          }
          hidden
        />
      </label>
    )
  }
)
