import { Settings } from 'ui'
import Delete from './Delete'
import Update from './Update'

const CompoundedSettings = Settings as CompoundedSettingsType
CompoundedSettings.Delete = Delete
CompoundedSettings.Update = Update

export default CompoundedSettings

type CompoundedSettingsType = typeof Settings & {
  Delete: typeof Delete
  Update: typeof Update
}
