import React from 'react'
import { Page } from 'ui'
import styles from './ErrorPage.module.scss'

export default function ErrorPage({ title }: ErrorPageProps) {
  return (
    <Page>
      <div className={styles.card}>{title}</div>
    </Page>
  )
}

type ErrorPageProps = {
  title: string
}
