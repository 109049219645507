import Router from 'api/Router'

const inventoryHistoryRouter = new Router({ pathname: '/inventories' })

export function getInventoryScheduleList({ date }: { date: string }) {
  return inventoryHistoryRouter.get<InventorySheduleListItemType[]>(
    '/schedule',
    { params: { date } }
  )
}

export type InventorySheduleListItemType = {
  inventory_id: number
  inventory_title: string
  park_id: number
  park_title: string
  from: string
  to: string
  last_history: null | InventoryHistoryType
}

export type InventoryHistoryType = {
  id: number
  inventory_id: number
  park_id: number
  park_title: string
  status: InventoryStatusType
  status_date: string
  updatedAt: string
  createdAt: string
}

export function addInventoryStatus(data: AddInventoryStatusDataType) {
  return inventoryHistoryRouter.post('/history', data)
}

export type InventoryStatusType = 'PACKED' | 'DELIVERED' | 'RETURNED'

export type AddInventoryStatusDataType = {
  inventoryId: number
  parkId: number
  status: InventoryStatusType
}

export type InventoryHistoryListItemType = {
  id: number
  inventory_title: string
  park_title: string
  status: InventoryStatusType
  status_date: string
}

export function getInventoryHistoryListById(id: number | `${number}`) {
  return inventoryHistoryRouter.get<InventoryHistoryListItemType[]>(
    `/${id}/history`
  )
}

export function updateInventoryStatusDateById(
  id: number | `${number}`,
  { statusDate }
) {
  return inventoryHistoryRouter.patch<InventoryHistoryListItemType[]>(
    `/history/${id}/date`,
    { statusDate }
  )
}

export function deleteInventoryStatusById(id: number | `${number}`) {
  return inventoryHistoryRouter.delete<InventoryHistoryListItemType[]>(
    `/history/${id}`
  )
}
