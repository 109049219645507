import React from 'react'
import { Page, Table } from 'ui'
import { useAuthStore, useSettingsStore } from 'store'

export default function Settings() {
  const {
    partyProgramComponent,
    setPartyProgramComponent,
    partyProgramComponents,
    programPriceType,
    programPriceTypes,
    setProgramPriceType,
  } = useSettingsStore()

  const { user } = useAuthStore()
  const isActor = user.role === 'ANIMATOR' || user.role === 'SHOWMAN'

  const style: React.CSSProperties = {
    color: 'var(--primary)',
    cursor: 'pointer',
    textDecoration: 'underline',
  }

  function handleChangePartyProgramComponent() {
    setPartyProgramComponent(
      Object.keys(partyProgramComponents).find(
        (key) => key !== partyProgramComponent.value
      )
    )
  }

  function handleChangenProgramPriceType() {
    const current = programPriceType.value
    const currentIndex = Object.keys(programPriceTypes).indexOf(current)
    let next =
      Object.keys(programPriceTypes)[currentIndex + 1] ||
      Object.keys(programPriceTypes)[0]

    // @ts-ignore
    if (user.role === 'MANAGER' && next === 'Salary') {
      next = Object.keys(programPriceTypes)[0]
    }

    setProgramPriceType(next)
  }

  return (
    <Page>
      <Page.Header title="Настройки" />
      <Table>
        <Table.Header>
          <Table.Col>Настройка</Table.Col>
          <Table.Col>Значение</Table.Col>
        </Table.Header>
        <Table.Row>
          <Table.Col>Вид программ в праздниках</Table.Col>
          <Table.Col onClick={handleChangePartyProgramComponent} style={style}>
            {partyProgramComponent.title}
          </Table.Col>
        </Table.Row>
        {!isActor && (
          <Table.Row>
            <Table.Col>Значение стоимости программы</Table.Col>
            <Table.Col onClick={handleChangenProgramPriceType} style={style}>
              {programPriceType.title}
            </Table.Col>
          </Table.Row>
        )}
      </Table>
    </Page>
  )
}
