import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import 'assets/scss/index.scss'
import 'swiper/swiper.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

Array.prototype.move = (array: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= array.length) {
    let i = newIndex - array.length + 1
    while (i--) array.push(undefined)
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])

  return array
}
