import React from 'react'
import classNames from 'classnames'
import { HandySvg } from 'handy-svg'
import { modifiedClassName } from 'utils'
import styles from './ButtonPlus.module.scss'
import { ButtonPlusProps } from './types'

import iconPlus from 'assets/svg/plus.svg'

export default function ButtonPlus({
  className,
  color = 'PRIMARY',
  onClick,
  size = 30,
  variant = 'PLUS',
}: ButtonPlusProps) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.container,
        className,
        modifiedClassName(styles, 'container', [
          color,
          size.toString(),
          variant,
        ])
      )}
    >
      <HandySvg src={iconPlus} />
    </div>
  )
}
