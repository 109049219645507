import React from 'react'
import { useAuthStore } from 'store'
import { Form } from 'components'
import { Input, Button, Select } from 'ui'
import { Core } from 'core'

export default function ParksReport() {
  const { parks } = useAuthStore()
  const [errors, setErrors] = React.useState(null)
  const [formData, setFormData] = React.useState({
    from: null,
    to: null,
    id: [],
    // internal: false,
  })

  const [fetching, setFetching] = React.useState(false)

  async function handleSubmit() {
    setFetching(true)
    await Core.Park.api
      .report(formData)
      .then(({ file }) => {
        window.open(process.env.REACT_APP_SERVER_URL + file, '_blank')
        setErrors(null)
      })
      .catch(({ response }) => {
        setErrors(response.data.errors)
      })
      .finally(() => setFetching(false))
  }

  return (
    <Form formData={formData} setFormData={setFormData} errors={errors} init={false}>
      <Form.Group title="Отчет по паркам">
        <Form.Control
          label={{ style: 'bold', text: 'Парки' }}
          component={Select}
          name="id"
          options={parks.map(({ title, id }) => ({ text: title, value: id }))}
          multiple={true}
          className="col col-12"
        />
        <Form.Control
          component={Input}
          name="from"
          type="date"
          label={{ style: 'bold', text: 'От' }}
          className="col col-12"
        />
        <Form.Control
          component={Input}
          name="to"
          type="date"
          label={{ style: 'bold', text: 'До' }}
          className="col col-12"
        />
        {/* <Form.Control
          component={(props) => (
            <input {...props} onChange={() => props.onChange(!props.value)} checked={!!props.value} />
          )}
          name="internal"
          type="checkbox"
          label={{ style: 'bold', text: 'Внутренние праздники' }}
          className="col col-12"
        /> */}
        <Button
          component="button"
          text="Составить отчет"
          onClick={handleSubmit}
          className="col col-12"
          fetching={fetching}
        />
      </Form.Group>
    </Form>
  )
}
