import React from 'react'
import { HandySvg } from 'handy-svg'
import styles from './Icon.module.scss'
import classNames from 'classnames'
import lightOrDarkColor from '@check-light-or-dark/color'

export default function Icon({ className, name, color }: IconProps) {
  const [icon, setIcon] = React.useState(null)

  React.useEffect(() => {
    async function importIcon() {
      await import('assets/svg/' + name + '.svg').then(setIcon)
    }

    importIcon()
  }, [])

  return (
    <div
      className={classNames(
        styles.container,
        className,
        styles[lightOrDarkColor(color)]
      )}
      style={{ backgroundColor: color }}
    >
      {icon && <HandySvg src={icon.default} />}
    </div>
  )
}

type IconProps = {
  className?: string
  color?: string
  name: string
}
