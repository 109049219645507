import { handleChangeProps } from '../types'

export default function handleChange({
  i,
  multiple,
  setOptions,
  setOpened,
}: handleChangeProps) {
  if (multiple) {
    return setOptions((prev) => {
      return i !== null
        ? prev.map((option, j) => ({
            ...option,
            selected: i === j ? !option.selected : option.selected,
          }))
        : prev.map((option) => ({ ...option, selected: true }))
    })
  }

  setOptions((prev) => {
    return prev.map((option, j) => {
      if (j === i) {
        option.selected = true
      } else {
        option.selected = false
      }

      return option
    })
  })

  setOpened(false)
}
