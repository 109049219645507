import React, { useEffect } from 'react'
import { Form } from 'components'
import styles from './Auth.module.scss'
import { Button, Input } from 'ui'
import { useLogin } from './hooks'
import { useCookies } from 'react-cookie'
import { useAlertStore, useAuthStore } from 'store'
import { Core, axios } from 'core'
import useAxiosWithIntersceptors from './hooks/useAxiosWithIntersceptors'

const ERRORS = []

export default function Auth() {
  useAxiosWithIntersceptors()

  const [{ token }, , removeCookie] = useCookies(['token'])
  const alert = useAlertStore()
  const authStore = useAuthStore()

  const [formData, setFormData] = React.useState({ phone: '', password: '' })
  const { login } = useLogin()

  useEffect(() => {
    if (token && !authStore.user) {
      async function loadUser() {
        try {
          alert.loading({ title: 'Загрузка пользователя...' })

          const { data } = await axios.get<GetUserResponse>('/auth/user')

          data.user.role = data.role

          const { capabilities, role } = data

          const sudo = role === 'DEVELOPER' || role === 'SUDO'

          Object.keys(Core).forEach((model) => {
            Object.keys(Core[model].capabilities).forEach((key) => {
              const value = Core[model].capabilities[key]
              const allowed = sudo || capabilities.indexOf(value) !== -1
              Core[model].capabilities[key] = allowed
            })
          })

          if (role === 'MANAGER') {
            Core.Program.capabilities.findAll = true
            Core.Program.capabilities.findOne = true
            Core.Program.capabilities.calculateCost = true
            Core.Party.capabilities.create = true
            Core.Party.capabilities.update = true
            Core.Party.capabilities.delete = true
            Core.Party.capabilities.findAll = true
            Core.Party.capabilities.findOne = true
            Core.PartyProgram.capabilities.create = true
            Core.PartyProgram.capabilities.update = true
            Core.PartyProgram.capabilities.delete = true
            Core.PartyProgram.capabilities.findAll = true
            Core.PartyProgram.capabilities.findOne = true
          }
          if (role === 'ANIMATOR' || role === 'SHOWMAN') {
            Core.Park.capabilities.findAll = true
            Core.Party.capabilities.findAll = true
            Core.Party.capabilities.findOne = true
            Core.User.capabilities.salary = true
          }
          if (role === 'EDITOR') {
            Core.User.capabilities.findAll = true
            Core.User.capabilities.findOne = true
            Core.User.capabilities.create = true
            Core.User.capabilities.update = true
            Core.Program.capabilities.create = true
            Core.Program.capabilities.update = true
            Core.Program.capabilities.findAll = true
            Core.Program.capabilities.findOne = true
            Core.Program.capabilities.calculateCost = true
            Core.ProgramRate.capabilities.create = true
            Core.ProgramRate.capabilities.update = true
            Core.ProgramRate.capabilities.findAll = true
            Core.Party.capabilities.create = true
            Core.Party.capabilities.update = true
            Core.Party.capabilities.delete = true
            Core.Party.capabilities.findAll = true
            Core.Party.capabilities.findOne = true
            Core.PartyProgram.capabilities.create = true
            Core.PartyProgram.capabilities.update = true
            Core.PartyProgram.capabilities.delete = true
            Core.PartyProgram.capabilities.findAll = true
            Core.PartyProgram.capabilities.findOne = true
            Core.PartyProgram.capabilities.chooseExecutor = true
            Core.PartyProgram.capabilities.chooseInventory = true
            Core.PartyProgram.capabilities.changeCost = true
            Core.Inventory.capabilities.create = true
            Core.Inventory.capabilities.update = true
            Core.Inventory.capabilities.findAll = true
            Core.Inventory.capabilities.findOne = true
          }
          if (role === 'DRESSER') {
            Core.Inventory.capabilities.findAll = true
            Core.Inventory.capabilities.update = true
            Core.Program.capabilities.findOne = true
            Core.Program.capabilities.findAll = true
            Core.Party.capabilities.findAll = true
            Core.Party.capabilities.findOne = true
            Core.PartyProgram.capabilities.chooseInventory = true
          }

          authStore.init(data)
        } catch (error) {
          console.log(error)
          removeCookie('token')
        } finally {
          alert.clear()
        }
      }

      loadUser()
    }
  }, [token, authStore.user])

  if (token && !authStore.user) {
    return null
  }

  return (
    <div className={styles.container}>
      <Form formData={formData} setFormData={setFormData} init={false} errors={ERRORS}>
        <Form.Group title="Авторизация">
          <Form.Control
            component={Input}
            label={{ text: 'Телефон' }}
            name="phone"
            mask="+7 (999) 999 99-99"
            className="col col-12"
          />
          <Form.Control
            component={Input}
            type="password"
            label={{ text: 'Пароль' }}
            name="password"
            className="col col-12"
          />
          <Button component="button" text="Войти" onClick={() => login(formData)} className="col col-12" />
        </Form.Group>
      </Form>
    </div>
  )
}

type GetUserResponse = {
  capabilities: Core.TPermission[]
  parks: Core.TPark[]
  role: string
  user: Core.TUser
}
