import Model from 'core/Model'

const inventoryApprovalSchema = {
  id: NaN,
  key: '',
  title: '',
}

export const InventoryApproval = new Model<TInventoryApproval>({
  url: '/inventory-approvals',
  schema: inventoryApprovalSchema,
  labels: {
    archive: '',
    create: '',
    creating: '',
    updating: '',
  },
})

export type TInventoryApproval = typeof inventoryApprovalSchema
