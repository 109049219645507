import { InventoryHistoryType } from 'api/endpoints'
import { ruLocaleDate } from 'utils'

export default function getStatus({
  lastHistory,
  parkId,
}: {
  lastHistory: InventoryHistoryType
  parkId: number
}) {
  const list = {
    PACKED: 'Упакован',
    RETURNED: 'Вернули',
    DELIVERED: 'Доставлен',
  }

  let title = list[lastHistory.status] || 'Неизвестный статус'

  const park =
    lastHistory.park_id === parkId ? ' ' : ` в «${lastHistory.park_title}» `

  if (park) {
    title += park
  }

  const date = ruLocaleDate(lastHistory.status_date, {
    day: '2-digit',
    year: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })

  title += date

  return title
}
