import Model from 'core/Model'

const programTypeSchema = {
  id: NaN,
  color: '',
  key: '',
  title: '',
}

export const ProgramType = new Model<TProgramType>({
  url: '/program-types',
  schema: programTypeSchema,
  labels: {
    archive: 'Типы программ',
    create: 'Добавить тип',
    search: 'Поиск по типам',
    creating: 'Добавление типа программ',
    updating: 'Редактирование типа программ',
    menuText: 'Типы программ',
  },
})

export type TProgramType = typeof programTypeSchema
