import React from 'react'
import Checkboxes from './Checkboxes'
import Control from './Control'
import ContainerCatalog from './ContainerCatalog'
import ContainerGallery from './ContainerGallery'
import ContainerLoop from './ContainerLoop'
import { Declensions, Group } from './components'
import { FormProps } from './types'
import SelectGender from './SelectGender'

export const FormContext = React.createContext<TFormContext>(null)

type TFormContext = {
  errors: any
  formData: any
  init: boolean
  setFormData: (value: any | ((prev: any) => any)) => void
}

export default function Form<T>({
  children,
  errors,
  init,
  formData,
  setFormData,
}: FormProps<T>) {

  return (
    <FormContext.Provider
      value={{
        errors,
        formData,
        init,
        setFormData,
      }}
    >
      {formData && children}
    </FormContext.Provider>
  )
}

Form.Catalog = ContainerCatalog
Form.Checkboxes = Checkboxes
Form.Control = Control
Form.Declensions = Declensions
Form.Gallery = ContainerGallery
Form.Group = Group
Form.Loop = ContainerLoop
Form.SelectGender = SelectGender
