import React from 'react'
import { Input, Page, Settings, Table } from 'ui'
import { FormControl } from 'components'
import {
  getInventoryScheduleList,
  addInventoryStatus,
  AddInventoryStatusDataType,
} from 'api/endpoints'
import useInventorySheduleApi from './api/useInventoryScheduleApi'
import { STATUSES_LIST, WITHOUT_STATUS } from './constants'
import { ruLocaleDate } from 'utils'
import getStatus from './utils/getStatus'
import { Link } from 'react-router-dom'

export default function InventoryParkPage() {
  const [schedule, scheduleApi] = useInventorySheduleApi()
  const [date, setDate] = React.useState(new Date().toJSON().split('T')[0])

  React.useEffect(() => {
    getInventoryScheduleList({ date }).then(scheduleApi.init)
  }, [date])

  async function addStatus(data: AddInventoryStatusDataType) {
    addInventoryStatus(data).then(scheduleApi.createHistory)
  }

  return (
    <Page>
      <Page.Header title="Таблица инвентаря" />
      <FormControl
        component={Input}
        label={{ style: 'bold', text: 'Дата' }}
        type="date"
        value={date}
        onChange={setDate}
      />
      <Table>
        <Table.Header>
          <Table.Col>Инвентарь</Table.Col>
          <Table.Col>Парк</Table.Col>
          <Table.Col>Время</Table.Col>
          <Table.Col>Статус</Table.Col>
          <Table.Col width={40} fallback="" />
        </Table.Header>
        {schedule.map((inventory, i) => {
          const from = ruLocaleDate(inventory.from, {
            minute: '2-digit',
            hour: '2-digit',
          })

          const to = ruLocaleDate(inventory.to, {
            minute: '2-digit',
            hour: '2-digit',
          })

          const status =
            STATUSES_LIST[inventory.last_history?.status] || WITHOUT_STATUS

          return (
            <Table.Row key={i}>
              <Table.Col>{inventory.inventory_title}</Table.Col>
              <Table.Col>{inventory.park_title}</Table.Col>
              <Table.Col>
                {from} — {to}
              </Table.Col>
              <Table.Col>
                {inventory.last_history &&
                  getStatus({
                    lastHistory: inventory.last_history,
                    parkId: inventory.park_id,
                  })}
              </Table.Col>
              <Table.Col>
                <Settings fallback="">
                  <Settings.Option
                    onClick={() =>
                      addStatus({
                        inventoryId: inventory.inventory_id,
                        parkId: inventory.park_id,
                        status: status.nextStatus,
                      })
                    }
                  >
                    {status.title}&nbsp;(текущее&nbsp;время)
                  </Settings.Option>
                  <Settings.Option
                    Component={Link}
                    to={`${inventory.inventory_id}`}
                  >
                    Посмотреть&nbsp;историю
                  </Settings.Option>
                </Settings>
              </Table.Col>
            </Table.Row>
          )
        })}
      </Table>
    </Page>
  )
}
