import classNames from 'classnames'
import styles from './Tools.module.scss'

export default function Row({ className, children, style }: RowProps) {
  return (
    <div className={classNames(styles.row, className)} style={style}>
      {children}
    </div>
  )
}

type RowProps = {
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
}
