import React from 'react'
import styles from './Report.module.scss'
import ParksReport from './ParksReport'
import SalaryReport from './SalaryReport'

const components = {
  PARKS: ParksReport,
  SALARY: SalaryReport,
}

export default function Report({ type }: { type: keyof typeof components }) {
  return <div className={styles.container}>{components[type]()}</div>
}
