import {
  deleteInventoryStatusById,
  getInventoryHistoryListById,
  InventoryHistoryListItemType,
  updateInventoryStatusDateById,
} from 'api/endpoints'
import { Form, FormControl } from 'components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAlertStore, useModalStore } from 'store'
import { Button, Input, Page, Settings, Table } from 'ui'
import { ruLocaleDate } from 'utils'

const STATUSES = {
  RETURNED: 'Возвращен',
  PACKED: 'Упакован',
  DELIVERED: 'Доставлен',
}

export default function InventoryHistoryPage() {
  const { id } = useParams()
  const [history, setHistory] = useState<InventoryHistoryListItemType[]>([])

  useEffect(() => {
    getInventoryHistoryListById(Number(id)).then(setHistory)
  }, [])

  const alert = useAlertStore()
  const modal = useModalStore()

  function handleDelete(id: number) {
    alert.confirm({
      title: 'Подтвердите удаление',
      buttons: [
        { text: 'Отмена', onClick: alert.clear, theme: 'LIGHT' },
        {
          text: 'Удалить',
          onClick: () =>
            deleteInventoryStatusById(id)
              .then(() =>
                setHistory((prev) => prev.filter((item) => item.id !== id))
              )
              .then(alert.clear),
          theme: 'DANGER',
        },
      ],
    })
  }

  return (
    <Page>
      <Page.Header title={`История «${history[0]?.inventory_title}»`} />
      <Table>
        <Table.Header>
          <Table.Col>Парк</Table.Col>
          <Table.Col>Статус</Table.Col>
          <Table.Col>Дата</Table.Col>
          <Table.Col width={40} fallback={''} />
        </Table.Header>
        {history.map((history) => {
          const timestamp = ruLocaleDate(history.status_date, {
            day: '2-digit',
            year: 'numeric',
            month: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
          })

          function handleOpenUpdateModal() {
            let timestamp =
              history.status_date.split('T')[0] +
              'T' +
              new Date(history.status_date).toLocaleTimeString('ru-Ru', {
                minute: '2-digit',
                hour: '2-digit',
              })

            function update() {
              updateInventoryStatusDateById(history.id, {
                statusDate: timestamp,
              })
                .then((res) =>
                  setHistory((prev) =>
                    prev.map((history) => {
                      // @ts-ignore
                      if (history.id === res.id) {
                        // @ts-ignore
                        history.status_date = res.statusDate
                      }

                      return history
                    })
                  )
                )
                .then(modal.close)
            }

            modal.open(
              <div
                style={{ padding: 30, background: '#fff', borderRadius: 20 }}
              >
                <FormControl
                  defaultValue={timestamp}
                  onChange={(value) => (timestamp = value)}
                  component={Input}
                  type={'datetime-local'}
                  name={'statusDate'}
                />
                <Button
                  style={{ marginTop: 20, width: '100%' }}
                  onClick={update}
                >
                  Сохранить
                </Button>
              </div>
            )
          }

          return (
            <Table.Row key={history.id}>
              <Table.Col>{history.park_title}</Table.Col>
              <Table.Col>{STATUSES[history.status]}</Table.Col>
              <Table.Col>{timestamp}</Table.Col>
              <Table.Col>
                <Settings fallback="">
                  <Settings.Option onClick={handleOpenUpdateModal}>
                    Изменить время
                  </Settings.Option>
                  <Settings.Option
                    onClick={() => handleDelete(Number(history.id))}
                  >
                    Удалить
                  </Settings.Option>
                </Settings>
              </Table.Col>
            </Table.Row>
          )
        })}
      </Table>
    </Page>
  )
}
