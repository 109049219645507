import Model from 'core/Model'
import { TProgram } from '../Program/Program'
import { TProgramRate } from '../ProgramRate/ProgramRate'
import { TUser } from '../User/User'
import { TParty } from '../Party/Party'
import Api from 'core/Api'
import { TInventory } from '../Inventory/Inventory'

const partyProgramSchema = {
  id: NaN,
  cost: null,
  salary: null,
  from: '',
  to: '',
  fromString: '',
  toString: '',
  rateId: NaN,
  success: null,
  partyId: NaN,
  programId: NaN,
}

class PartyProgramApi<TPartyProgram> extends Api<TPartyProgram> {
  async chooseExecutor(
    id: number,
    data: { userId: number; date: Date; hard?: boolean }
  ) {
    const url = [this.url, id, 'executors'].join('/')
    return await this.instance.post(url, data).then(({ data }) => data)
  }

  async chooseInventory(
    id: number,
    data: { inventoryId: number; date: Date; hard?: boolean }
  ) {
    const url = [this.url, id, 'inventories'].join('/')
    return await this.instance.post(url, data).then(({ data }) => data)
  }

  async changeCost(id: number, data: { cost: number; salary: number }) {
    return await this.instance.patch(`${this.url}/${id}/cost`, data)
  }
}

export const PartyProgram = new Model<
  TPartyProgram,
  PartyProgramApi<TPartyProgram>
>({
  Api: PartyProgramApi,
  url: '/party-programs',
  schema: partyProgramSchema,
  capabilities: {
    chooseExecutor: 'post.party-programs/:id/executors',
    chooseInventory: 'post.party-programs/:id/inventories',
    hardDelete: 'delete.party-programs/:id?hard=true',
    changeCost: 'patch.party-programs/:id/cost',
  },
})

export type TPartyProgram = Omit<
  typeof partyProgramSchema,
  'cost' | 'success' | 'from' | 'to' | 'salary'
> & {
  salary: number
  cost: number
  from: Date
  to: Date
  success: boolean
  rate?: TProgramRate
  party?: TParty
  program?: TProgram
  inventories?: TInventory[]
  executors?: TUser[]
}

interface ChooseInventoriesData {
  inventoryId: number
  date: Date
}
