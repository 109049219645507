import React from 'react'
import styles from './Gallery.module.scss'
import { ButtonPlus, Spinner } from 'ui'
import { ImageProps } from './types'
import { Media } from 'ui'

export default function Item({
  src,
  uploaded,
  onDelete,
  onDragStart,
  onDragEnd,
  onDragEnter,
}: ImageProps) {
  const [dragPosition, setDragPosition] = React.useState(null)

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()

    setDragPosition(e.pageX < ref.current.offsetLeft ? 'LEFT' : 'RIGHT')
    onDragEnter()
  }

  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <div
      ref={ref}
      className={styles.item}
      onDragStart={onDragStart}
      onDragEnd={() => onDragEnd(dragPosition)}
      onDragLeave={handleDragOver}
      onDragOver={handleDragOver}
      draggable={true}
    >
      <ButtonPlus
        variant="CROSS"
        color="DANGER"
        className={styles.delete}
        onClick={onDelete}
      />
      <Media component="div" src={src} className={styles.media} />
      <Spinner show={!uploaded} className={styles.spinner} />
    </div>
  )
}
