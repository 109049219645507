import React from 'react'
import axios from 'axios'
import styles from './Media.module.scss'
import { Icon } from 'ui'
import classNames from 'classnames'
import { useModalStore } from 'store'

export default React.memo(
  function Media({
    className,
    component = 'img',
    src,
    open = [src],
  }: MediaProps) {
    const modal = useModalStore()

    const [image, setImage] = React.useState(src)

    const isYoutube = src.includes('youtube:')

    React.useEffect(() => {
      async function fetchData() {
        const youtubeId = src.replace('youtube:', '').split('?')[0]

        await axios
          .get(
            `https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${youtubeId}&format=json/`
          )
          .then(({ data }) => setImage(data.thumbnail_url))
          .catch(({ response }) => console.log(response.data))
      }

      if (isYoutube) {
        fetchData()
      }
    }, [])

    function handleOpen() {
      if (open) {
        const gallery = open.map((src) => {
          const isYoutube = src.includes('youtube:')
          const Component = isYoutube ? 'iframe' : 'img'

          return (
            <Component
              width={isYoutube ? 1200 : undefined}
              height={isYoutube ? 600 : undefined}
              frameBorder={isYoutube ? 0 : undefined}
              src={src.replace('youtube:', 'https://www.youtube.com/embed/')}
            />
          )
        })

        // @ts-ignore
        modal.open(gallery)
      }
    }

    return (
      <div
        className={classNames(styles.container, className)}
        onClick={handleOpen}
        style={{ backgroundImage: component === 'div' && `url(${image})` }}
      >
        {isYoutube && <Icon name="play" className={styles.play} />}
        {component === 'img' && <img src={image} />}
      </div>
    )
  },
  (prev, next) => prev.src === next.src
)

type MediaProps = {
  component?: 'img' | 'div'
  className?: string
  open?: string[]
  src: string
}
