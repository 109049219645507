import React from 'react'
import styles from './Page.module.scss'

export default function Header({ children, title }: HeaderProps) {
  return (
    <div className={styles.header}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  )
}

type HeaderProps = {
  children?: React.ReactNode
  title: string
}
